/**
 * BOR-todo: this is logacy code from first months of Eventival
 * Temporarily (enshallah) moved to styleguide together with __() function needed for modal.js
 * All depenancies to be cut off and this file should get annihilated
 */

(function($)
{
  /**
    * Testing variables type.
    *
    * @author Michal Kandr
    **/
  $.extend({
    isBoolean: function(variable)
    {
      return typeof variable == 'boolean';
    },

    isBool: function(variable)
    {
      return $.isBoolean(variable);
    },

    isNumber: function(variable)
    {
      return typeof variable == 'number';
    },

    isString: function(variable)
    {
      return typeof variable == 'string';
    },

    isObject: function(variable)
    {
      return typeof variable == 'object';
    },

    isUndefined: function(variable)
    {
      return typeof variable == 'undefined';
    },

    isSet: function(variable)
    {
      return ! $.isUndefined(variable) && variable !== null;
    },

    isEmpty: function(variable)
    {
      return ! $.isSet(variable) || ! variable;
    },

    isJQuery: function(variable)
    {
      return $.isSet(variable) && $.isSet(variable.jquery);
    },

    /**
      * Escape string for usage in regular expression.
      * @param string str
      * @return string
      */  
    regExpEscape: function(str)
    {
      var specials = new RegExp("[.*+?|()\\[\\]{}\\\\]", "g"); // .*+?|()[]{}\
      return str.replace(specials, "\\$&");
    },
    
    htmlEntityEncode: function(str)
    {
      var chars = [
        ['&', '&amp;'],
        ['>', '&gt;'],
        ['<', '&lt;'],
        ['"', '&quot;'],
        ['\'', '&#39;']
      ];
      if( ! str)
      {
        return str;
      }
      for(var i=0; i<chars.length; ++ i)
      {
        str = str.replace(chars[i][0], chars[i][1]);
      }
      return str;
    },

    arrayRemove : function(array, index){
      array.splice(index, 1);
    },

    evMagic: {
      element:"",
      magicUncheckHandler:null,
      magicHandler: function(e) {
        if($(this).attr("checked")) {
            $("#"+$.evMagic.element).removeClass("magic_hidden");
          }else{
            $("#"+$.evMagic.element).addClass("magic_hidden");
            if($.evMagic.magicUncheckHandler!==null) {
              $.evMagic.magicUncheckHandler();
            }
        }
      }
    },
    /**
      * Debug messeges are displayed only in developer mode
      */
    debug: function(type, msg) {
      if(GLOBE.config.devel==1 && ! $.isUndefined(window.console)) {
        if (type=="error") {
          window.console.error(msg);
        }
        else if(type=="warn") {
          window.console.warn(msg);
        }
        else if(type=="info") {
          window.console.info(msg);
        }
        else {
          window.console.debug(msg);
        }
      }
    },
    exists: function(expression) {
      if($(expression).length>0) {
        return true;
      }
      else {
        return false;
      }
    },
    hex: function(x) {
      return ("0" + parseInt(x).toString(16)).slice(-2);
    },
    rgb2hex: function(rgb) {
      if (rgb.search("rgb") == -1) {
        return rgb;
      }
      else {
        rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/);
        return "#" + $.hex(rgb[1]) + $.hex(rgb[2]) + $.hex(rgb[3]); 
      }
    }
  });

  /**
    * Universal function to hide a certain dom element by checking/unchecking chkbx button
    *
    * @usage $("#[id of a checkbox]").checkboxMagic("[id of an element be controlled by the checkbox]", handler);
    * @var _domElementId id of DOM element, that we are trying to hide
    * @var _uncheckHandler handler to launch upon unchecking chkbx
    */
  $.fn.evCheckboxMagic = function(_domElementId, _uncheckHandler){
    if(_domElementId!==undefined) {
      $.evMagic.element = _domElementId;
      $(this).click(function() {
          if($(this).is(":checked")) {
              $("#"+_domElementId).removeClass("magic_hidden");
            }else{
              $("#"+_domElementId).addClass("magic_hidden");
              if($.evMagic.magicUncheckHandler!==null) { /*here paste uncheck handler code*/
                $.evMagic.magicUncheckHandler();
              }
          }
        });
      if(!$(this).is(":checked")) {
  //console.debug("#"+_domElementId);
        $("#"+_domElementId).addClass("magic_hidden");
        if(_uncheckHandler!==undefined) { /*here paste uncheck handler code*/
          _uncheckHandler();
        }
      }
    }
    if(_uncheckHandler!==undefined)
      {
        $.evMagic.magicUncheckHandler = _uncheckHandler;
      }
  };

  $.fn.insertAtCaret = function (myValue) {
    return this.each(function(){
      //IE support
      if (document.selection) {
        this.focus();
        sel = document.selection.createRange();
        sel.text = myValue;
        this.focus();
        //MOZILLA / NETSCAPE support
      } else if (this.selectionStart || this.selectionStart == '0') {
        var startPos = this.selectionStart;
        var endPos = this.selectionEnd;
        var scrollTop = this.scrollTop;
        this.value = this.value.substring(0, startPos)+ myValue+ this.value.substring(endPos,this.value.length);
        this.focus();
        this.selectionStart = startPos + myValue.length;
        this.selectionEnd = startPos + myValue.length;
        this.scrollTop = scrollTop;
      } else {
        this.value += myValue;
        this.focus();
      }
    });
  };

  /**
    * Get elements maximal z-index.
    * Maximal z-index is maximum of z-indexes of element and it's parents
    * @param object elem
    * @return integer
    **/
  $.fn.elemZIndex = function (elem){
    var zIndex = parseInt($(this).css('z-index'), 10);
    zIndex = isNaN(zIndex) ? 0 : zIndex;

    $(this).parents().each(function(){
      var parentZIndex = parseInt($(this).css('z-index'), 10);
      if( ! isNaN(parentZIndex) && parentZIndex > zIndex) {
        zIndex = parentZIndex;
      }
    });

    return zIndex;
  };
})(jQuery);

Array.prototype.inArray = function (value)
{
  var i;
  for (i=0; i < this.length; i++) {
    // Matches identical (===), not just similar (==).
      if (this[i] == value) {
        return true;
      }
    }
    return false;
};
Array.prototype.isSubset = function (set)
{
  var isSubset=0, arr = this;
  if ($.isUndefined(arr) || !arr || $.isUndefined(set) || ! $.isFunction(arr.inArray)) {
    return false;
  }

  $.each(set, function(k, v) {
    if (arr.inArray(v)) {
      isSubset += 1;
    }
  });
  if (isSubset===set.length) {
    return true;
  }
  else {
    return false;
  }
};
Array.prototype.empty = function ()
{
  if (this.length===0) {
		return true;
	}
  else {
		return false;
	}
};
function DateInterval(_from, _to) {
  if (_from instanceof Date && _to instanceof Date) {
    this.from = _from;
    this.to = _to;
  }
  else {
		throw 'Wrong input parametrs. Both must be of type Date. ('+_from+', '+_to+')';
	}
}
DateInterval.prototype.intersects = function(_interval) {
  if (_interval instanceof DateInterval) {
    if ((_interval.to<this.from && _interval.to<this.to) || (_interval.from>this.to && _interval.from>this.from)) {
      return false;
    }
    else {
      return true;
    }
  }
  else {
    throw 'Wrong input parametr. Must be of type Interval';
  }
};
Date.prototype.createInterval = function(_date) {
  if (_date instanceof Date) {
    if (this<_date) {
      return new DateInterval(this, _date);
    }
    else {
      return new DateInterval(_date, this);
    }

    $.debug('log', 'Interval created..');
  }
  else {
    throw 'Wrong input parametr. Must be of type Date. ('+_from+', '+_to+')';
  }
};
