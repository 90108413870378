/** js-toggle-code handler */
(function(window) {
  const toggleButtons = document.querySelectorAll('.js-toggle-code');

  // create show code icon for each element
  for (let i = 0; i < toggleButtons.length; i++) {
    let icon = document.createElement("i");
    Object.assign(icon, {
      className: "far fa-code",
      id: i + 1,
      title: "Show code",
      style: "position: absolute; right: 5px; top: 5px;"
    });
    icon.addEventListener('click', toggle, false);
    toggleButtons[i].append(icon);
  }

  if (typeof window.evDevEnv != 'undefined' && window.evDevEnv)
    console.log('Toggle Code initialized!');

  function toggle(e) {
    const code = e.currentTarget.parentNode.nextElementSibling.nextElementSibling;
    code && code.classList.toggle("open");

    const copyCode = e.currentTarget.parentNode.nextElementSibling;
    copyCode && copyCode.classList.toggle('dn');
  }

})(window);

