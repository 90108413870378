/** .js-toggle-menu handler - used in mobile navigation*/
(function(window){
  const togglBtns = document.querySelectorAll('.js-toggle-menu');

  for (let i = 0; i < togglBtns.length; i++) {
    togglBtns[i].addEventListener('click', toggle, false);
  }

  function toggle(e) {
    const el = e.currentTarget
      , navCls = el.dataset.target
      , nav = document.querySelector(navCls)
      , desktopActive = document.querySelector('.desktopmenu:not(.dn)');

    nav.classList.contains('dn') ? nav.classList.remove('dn') : nav.classList.add('dn');
    if (desktopActive) {
      desktopActive.classList.remove('db');
      desktopActive.classList.add('dn');
    }
  }
})(window);
