
// Get the textarea who's index matches the index of the button
function copy_code_handler() {
  const area = this.parentElement.querySelector('textarea');

  if( area ) {
    area.select();
    try {
      Snackbar.success('Code copied!');
    } catch (err) {
      Snackbar.error('Whoops, unable to copy');
    }
  }
}

export default {
  start: function start () {
    const copyAreas = document.querySelectorAll('.js-copy-code')

    Array.prototype.forEach.call(copyAreas, (container, index) => {
      const copyButton = document.createElement("button");
      let id = index + 1;
      Object.assign(copyButton, {
        id: `copy${id}`,
        innerHTML: `<i class="far fa-copy"></i>`
      });
      copyButton.addEventListener('click', copy_code_handler);
      container.append(copyButton);
      container.classList.add('dn');
    });

    if (typeof window.evDevEnv != 'undefined' && window.evDevEnv) {
      console.log('Copy code started.');
    }
  }
}
