


// template for displaying results
const allItemsTpl = `
<table>
  <thead>
    <tr><th>Section</th><th>Title</th><th>Description</th></tr>
  </thead>
  <tbody>
  {{#hits}}
    <tr>
      <td class="capitalize">{{section}}</td>
      <td><a href="{{url}}">{{title}}</a></td>
      <td>
      {{#description}}{{description}}{{/description}}{{^description}}{{{summary}}}{{/description}}
      <br>
      {{#tags}}<span class="tag tag--primary">{{.}}</i></span>{{/tags}}
      </td>
    </tr>
  {{/hits}}
  </tbody>
</table>
<div class="algolia-docsearch-footer">
  Search by <a class="algolia-docsearch-footer--logo" href="https://www.algolia.com/docsearch">Algolia</a>
</div>
`;

// Search
const eventivalSearchOpts = {
  appId: '6PXOK5FUAM',
  apiKey: '1a4f485c887cb783746fc90ef76da958',
  indexName: 'styleguide.eventival.com',
  searchFunction: function (helper) {
    if (helper.state.query === '') {
        document.querySelector('#hits').innerHTML = '';
        return;
    }
    helper.search();
  }
};
const search = instantsearch(eventivalSearchOpts);

// initialize SearchBox
search.addWidget(
  instantsearch.widgets.searchBox({
    container: '#search-box',
    placeholder: 'Search styleguide'
  })
);



search.addWidget(
  instantsearch.widgets.hits({
    container: '#hits',
    templates: {
        empty: 'No results',
        allItems: allItemsTpl
      }
  })
);
  
export default search;
