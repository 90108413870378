window.addEventListener('load', function handleModals() {
    let modalTriggerBasic = document.querySelector(".js-modal-trigger-basic");
    let modalTriggerError = document.querySelector(".js-modal-trigger-error");
    let modalTriggerConfirm = document.querySelector(".js-modal-trigger-confirm");
  let buttonsBasic = '<div class="flex-gap--right"><button type="submit" value="Cancel" class="btn btn--secondary js-confirm_cancel">Cancel</button><button type="submit" value="Save" class="btn btn--primary js-confirm_save">Save</button></div>';
  let buttonsError = '<div class="flex-gap--right"><button type="submit" value="Close" class="btn btn--secondary js-confirm_close">Close</button></div>';

    if (modalTriggerBasic) {

        modalTriggerBasic.addEventListener("click", () => {
			const modal = evModal.show(__('Basic modal'),
        '<form class="mb8">' +
					'<div class="elastic-grid--vertical">' +
						'<div class="form-group">' +
							'<textarea name="note" rows="5" spellcheck="false" class="res-vertical">' +
							'</textarea>' +
						'</div>' +
					'</div>' +
				'</form>\n' + buttonsBasic, {
				dialogId: 'question', dialogClass: 'js-test-dialog maxw40rem',
				onLoad: function () {
					document.querySelector('.js-test-dialog .js-confirm_save').addEventListener('click', function (event) {
						window.confirm("Lets' go for lunch!");
					});
					document.querySelector('.js-test-dialog .js-confirm_cancel').addEventListener('click', function (event) {
						modal.close();
					});
				}
			});
		});
    }

    if (modalTriggerError) {

        modalTriggerError.addEventListener("click", () => {
          var modal = evModal.error(__('Error modal'), '<p class="mb8">Internal server error, please contact our support</p>\n' + buttonsError, {
				dialogId: 'error', dialogClass: 'js-test-error-dialog maxw40rem',
				onLoad: function () {
					document.querySelector('.js-test-error-dialog .js-confirm_close').addEventListener('click', function (event) {
						modal.close();
					});
				}
			});
		});
    }

    if (modalTriggerConfirm) {

        modalTriggerConfirm.addEventListener("click", () => {
            evModal.confirm('Modal confirm', 'Do you really want to send this email?', { buttonTextYes: 'Yes', buttonTextNo: 'No' });
        });
    }
});
