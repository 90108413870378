/**
 * .js-table class handler - allows to toggle specified classes to switch between desktop and mobile view automatically based on table width, and parent's available width
 *
 * This script also uses observeAddedNodes (based on MutationObserver) to bind all elements appeared in the future
 *
 * Main table element sould contain both `table` class for style and `js-table` class for auto toggle
 * `<div class="table js-table">`
 *
 * */

import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

(function (window) {
  window.addEventListener('load', function () {
    const viewMobileClass = 'table--force-mobile'; // compact view using elastic grid

    const childSelector = '.js-table:not(.js-table--is-bound)'; // DOM selector defining all elements which should be observed and whic hare not bound yet

    const resizeObserver = new ResizeObserverPolyfill(entries => {
      entries.forEach((entry) => {
        const availableWidth = entry.contentBoxSize[0].inlineSize;
        evTableUpdateView(entry.target, availableWidth);
      });
    });

    // first bind all tables loaded with document
    let tableElems = document.querySelectorAll(childSelector);
    for (var i = 0; i < tableElems.length; i++) {
      bindTable(tableElems[i]);
    }

    // after all tables are bound, trigger window resize to update their width
    window.dispatchEvent(new Event('resize'));

    // then use the MutationObserver to bind future elements
    document.body.observeAddedNodes(childSelector, bindTable);

    // bindTable function defines ResizeObserver
    function bindTable(tableElem) {
      // check that the function is called with the right table element
      if (!tableElem.classList.contains('js-table')) {
        return;
      }

      if (tableElem.classList.contains('js-table--is-bound')) {
        return;
      }

      // mark table as bound to prevent multiple binding
      tableElem.classList.add('js-table--is-bound');

      // force table in desktop mode for measurement
      tableElem.classList.remove(viewMobileClass);

      // save minimum width
      {
        // get the original width in case it was set
        const originalWidth = tableElem.style.width;
        // set the table's width to 1px (0 does not work)
        tableElem.style.width = '1px';
        // get the current width
        const smallestWidth = tableElem.scrollWidth;
        tableElem.dataset.calculatedMinWidth = smallestWidth;
        // set the original width back
        tableElem.style.width = originalWidth;
      }

      // per spec, this will also fire the callback when we start observing
      resizeObserver.observe(tableElem);
    }

    function evTableUpdateView(triggerElement, availableWidth) {
      if (typeof triggerElement === "undefined") {
        return;
      }

      const desktopTableWidth = Number(triggerElement.dataset.calculatedMinWidth);

      // if (triggerElement.classList.contains("testing"))
      //   console.log(`Comparing ${desktopTableWidth} with ${availableWidth}`);

      // add 2 for leeway
      if (availableWidth > desktopTableWidth + 2) {
        triggerElement.classList.remove(viewMobileClass);
      } else {
        triggerElement.classList.add(viewMobileClass);
      }

    };

  }, false);
})
(window);
