/**
 * .js-toggle class handler - allows to toggle specified classes
 *
 * Source element
 * `<div class="js-toggle arrow-angle icon-before" data-toggle_target_id="foo" data-classes="[name of class different than is-open]">Foo</div>`
 *
 * Destination element:
 * `<div data-toggle_id="foo" class="js-toggle-content is-open">`
 *
 * */

(function (window) {
  window.addEventListener('load', function() {

    // define highlight class to simplify refactoring
    const hlClass = 'li--highlighted';

    document.body.addEventForChildren('click', '.js-toggle', evToggle, false);

    // evToggle function is defined extra to distinguish between trigger by click and by javascript
    function evToggle(triggerElement) {
      if (typeof triggerElement === "undefined") {
        console.log('triggerElement undefined');
        return;
      }

      const classes = triggerElement.dataset.classes,
      contentElements = triggerElement.getevToggleContent();

      if (!contentElements.length)
        return true;

      triggerElement.classList.toggle('is-open');

      // update the cookie if data-toggle_cookie_key was provided
      const cookieKey = triggerElement.dataset.toggle_cookie_key;
      if (cookieKey) {
        const cookieValue = triggerElement.classList.contains('is-open');
        const cookieName = triggerElement.dataset.toggle_cookie_name;
        window.evCookies.updateValue(cookieName, cookieKey, cookieValue);
      }

      for (var i = 0; i < contentElements.length; ++i) {
        const contentElement = contentElements[i];

        // if targetElement was not found, do nothing
        if (contentElement == null) {
          return;
        }

        contentElement && classes && classes.split(' ').forEach(function (cls) {
          if (cls !== 'is-open') {
            contentElement.classList.toggle(cls);
          }
        });

        contentElement.classList.toggle('is-open');

        // if only display style is to be toggled then terminate
        const isDisplayToggle = contentElement.classList.contains("toggle-content--display");
        if (isDisplayToggle) {
          contentElement.classList.remove("toggle-content--default-open");
          continue;
        }

        const isDropdown = contentElement.parentElement.classList.contains("js-dropdown");
        const isAllMediaDropdown = contentElement.parentElement.classList.contains("all-media-dropdown");
        const isDropdownAtWideScreen = isDropdown && (isMediaQueryWide() || isAllMediaDropdown);

        // close the dropdown on mouseleave for more then 2 seconds
        // the event listeners are added again with every opening of the dropdown and they ar removed after the timeout executes
        if (isDropdownAtWideScreen && contentElement.classList.contains('is-open')) {
          let hoverTimer;

          const hoverIntentClear = function(e) {
            if (hoverTimer) hoverTimer = clearTimeout(hoverTimer);
          };

          const hoverIntentRegister = function(e) {
            hoverTimer = setTimeout(function(){
              if (e.target.children[0].classList.contains('is-open')) {
                evToggle(e.target.children[0]);
              }
              // removing event listeners to prevent multiple execution
              e.target.removeEventListener('mouseenter', hoverIntentClear);
              e.target.removeEventListener('mouseleave', hoverIntentRegister);
            }, 1000);
          };

          triggerElement.parentElement.addEventListener("mouseenter", hoverIntentClear);
          triggerElement.parentElement.addEventListener("mouseleave", hoverIntentRegister);
        }

        // opening toggle-content
        if (contentElement.classList.contains("is-open")) {
          if (isDropdownAtWideScreen) {
            contentElement.style.maxHeight = "none";
          } else {
            // make sure the default css is applied
            contentElement.style.maxHeight = null;

            // define one-time triggered unsetting of the max-height after opening transition to avoid overflow hidden
            contentElement.onTransitionEndOnce(function() {
              // check if toggle was not closed meanwhile
              if (contentElement.classList.contains("is-open"))
                contentElement.style.maxHeight = "none";
            });

            // start the opening transition
            contentElement.style.maxHeight = contentElement.scrollHeight + "px";
          }

          // if toggle-content contains a multilevel-select component, focus its input
          const multilevleSelectInput = contentElement.querySelector('.js-multilevel-select input');
          if (multilevleSelectInput) {
            multilevleSelectInput.focus();
          }
        }
        // closing toggle-content
        else {
          if (!isDropdownAtWideScreen) {
            // max-height should be set to "none" right now
            // make sure the initial max-height is set to exact px, so that the animation is fluent
            contentElement.style.maxHeight = contentElement.scrollHeight + "px";
            // remove the class to apply default max-heigt: 0;
            contentElement.classList.remove("toggle-content--default-open");
          }

          // this nasty code enables making the first max-height setting independent on the second one
          // the firs should be non-transitioned, the second one should be with transition
          setTimeout(function() {
            // start the closing transition
            contentElement.style.maxHeight = null;
          }, 10);

          // clean hlclass from all list items
          contentElement.evToggleCleanLiHighlight();
        }
      }
    }

    Object.defineProperty(Object.prototype, 'evToggle', {
      value : function (eventName, childSelector, cb, addEventListenerOptions) {
        if (!this.classList.contains('js-toggle'))
          return null;

        evToggle(this);
      },
      enumerable : false
    });

    // on every click all open dropdowns not containing clicked element get closed
    // only at wide screens
    window.onclick = function(event) {
      const dropdownClass = '.js-dropdown' + (isMediaQueryWide() ? '' : '.all-media-dropdown');

      const openDropdownElems = document.querySelectorAll(dropdownClass+' > .js-toggle.is-open');

      for (let i = 0; i < openDropdownElems.length; i++) {
        const dropdownElem = openDropdownElems[i];
        if (!dropdownElem.parentNode.contains(event.target) && !dropdownElem.isSameNode(event.target)) {
          evToggle(dropdownElem);
        }
      }
    };

    // on every click inside an open dropdown's content (direct) list item, close the dropdown if class `js-dropdown--noclose-onclick` was not added to any parent
    // only at wide screens
    document.body.addEventForChildren('click', '.js-dropdown > ul.js-toggle-content.is-open > li, .js-dropdown > .js-toggle-content.is-open > ul > li', function(el) {
      if (!isMediaQueryWide() && (!el.closest('.js-dropdown') || !el.closest('.js-dropdown').classList.contains("all-media-dropdown")))
        return true;

      if (!el.closest('.js-dropdown--noclose-onclick')) {
        evToggle(el.closest('.js-toggle-content').getevToggle());
      }
    }, false);

    // function for encapsulating check for media query defined in css as --wide
    // BOR-todo: this could be moved to common_functions.js to be used elsewhere
    const isMediaQueryWide = function() {
      return window.matchMedia("(min-width: 62.5em)").matches;
    };

    // function getevToggle finds js-toggle element (trigger) assigned to the js-toggle-content element on which it is called
    // this is an inverse function to getevToggleContent
    // the trigger elem can be found by data-toggle_target_id attribute, nextElementpreviousElementSiblingSibling or parent's previousElementSibling
    Object.defineProperty(Object.prototype, 'getevToggle', {
      value : function (eventName, childSelector, cb, addEventListenerOptions) {
        if (!this.classList.contains('js-toggle-content'))
          return null;

        const targetId = this.dataset.toggle_id;

        // if targetId is defined, try to find element which uses it
        if (targetId) {
          return document.querySelector("[data-toggle_target_id='"+targetId+"']");
        }

        // if targetId isnot  defined, try to find the previous sibling or parent's previous sibling as a default behaviour
        // first try previous sibling
        var sibling = this.previousElementSibling;
        // the toggle container must have class js-toggle-content
        if (sibling && typeof sibling.classList !== 'undefined' && sibling.classList.contains('js-toggle')) {
          return sibling;
        }

        // if previous sibling does not work, try parent's previous sibling
        var sibling = this.parentElement.previousElementSibling;
        // the toggle container must have class js-toggle-content
        if (sibling && typeof sibling.classList !== 'undefined' && sibling.classList.contains('js-toggle')) {
          return sibling;
        }

        return null;
      },
      enumerable : false
    });

    // function getevToggleContent finds js-toggle-content element assigned to the js-toggle element on which it is called
    // this is an inverse function to getevToggle
    // the content elem can be found by data-toggle_target_id attribute, nextElementSibling or parent's nextElementSibling
    Object.defineProperty(Object.prototype, 'getevToggleContent', {
      value : function (eventName, childSelector, cb, addEventListenerOptions) {
        if (!this.classList.contains('js-toggle'))
          return [];

		  const targetId = this.dataset.toggle_target_id;

		  // if targetId is defined, try to find that element
        if (targetId) {
          return document.querySelectorAll("[data-toggle_id='"+targetId+"']");
        }

        // if targetId isnot  defined, try to find the next sibling or parent's next sibling as a default behaviour
        // first try next sibling
		  let sibling = this.nextElementSibling;
		  // the toggle container must have class js-toggle-content
        if (sibling && typeof sibling.classList !== 'undefined' && sibling.classList.contains('js-toggle-content')) {
          return [sibling];
        }

        // if next sibling does not work, try parent's next sibling
		  sibling = this.parentElement.nextElementSibling;
		  // the toggle container must have class js-toggle-content
        if (sibling && typeof sibling.classList !== 'undefined' && sibling.classList.contains('js-toggle-content')) {
          return [sibling];
        }

        return [];
      },
      enumerable : false
    });

    // simulate hover efect by adding hlClass on mouseover and removing it on mouseout
    document.body.addEventForChildren('mouseover', '.js-dropdown > ul.js-toggle-content > li, .js-dropdown > .js-toggle-content > ul > li', function(el) {
      // first clean all highlights, js-toggle-content might be the same as ul its parent, try both
      el.parentElement.evToggleCleanLiHighlight();
      el.parentElement.parentElement.evToggleCleanLiHighlight();

      el.classList.add(hlClass);
    }, false);

    document.body.addEventForChildren('mouseout', '.js-dropdown > ul.js-toggle-content > li, .js-dropdown > .js-toggle-content > ul > li', function(el) {
      el.classList.remove(hlClass);
    }, false);

    // function removes hlClass from all list items directly in js-toggle-content element on which it is called
    Object.defineProperty(Object.prototype, 'evToggleCleanLiHighlight', {
      value : function (eventName, childSelector, cb, addEventListenerOptions) {
        if (!this.classList.contains('js-toggle-content'))
          return null;

        // the list can be either the s-toggle-content or its direct child
        let listElem = null;
        if (this.nodeName === 'UL')
          listElem = this;
        else
          listElem = this.querySelector('ul');

        if (!listElem)
          return null;

        // make sure once more we have the right ul
        if (listElem.classList.contains('js-toggle-content') || listElem.parentElement.classList.contains('js-toggle-content')) {
          for (let i = 0; i < listElem.children.length; i++) {
            if (listElem.children[i].classList.contains(hlClass)) {
              listElem.children[i].classList.remove(hlClass);
            }
          }
        }
      },
      enumerable : false
    });

    // listen to keydown on all document and navigate through the last open dropdown if any exists
    // so far we support only arrow up, down, right, enter and escape
    document.onkeydown = function(e) {
      let i;
      let x = 0;
      if (e.keyCode === 38) // arrow up
        x = -1;
      else if (e.keyCode === 40) // arrow down
        x = 1;
      else if (e.keyCode === 13 || // enter
               e.keyCode === 39 || // arrow right
               e.keyCode === 37 || // arrow left
               e.keyCode === 27) // escape
        x = e.keyCode;
      else
        return;

      const dropdownElems = document.querySelectorAll('.js-dropdown > .js-toggle.is-open');
      if (!dropdownElems.length) {
        return true;
      }

      // take just the last open dropdown element
      const lastDropdownElem = dropdownElems[dropdownElems.length-1];

      const dropdownContentelements = lastDropdownElem.getevToggleContent();
      if (!dropdownContentelements.length) {
        return true;
      }

      for (var j = 0; j < dropdownContentelements.length; ++j) {
        const dropdownContent = dropdownContentelements[j]

        // escape key closes the dropdown
        if (x === 27 || x === 37) {
          evToggle(lastDropdownElem);
          return false;
        }

        let dropdownContentList = null,
          dropdownContentItems = null;

        if (dropdownContent.nodeName === 'UL') {
          dropdownContentList = dropdownContent;
          dropdownContentItems = dropdownContent.children;
        }
        else {
          // TODO once `:scope` becomes more supported, this code for finding direct child by class will not be needed
          for (i = 0; i < dropdownContent.children.length; i++) {
            if (dropdownContent.children[i].nodeName === 'UL') {
              dropdownContentList = dropdownContent.children[i];
              dropdownContentItems = dropdownContent.children[i].children;
              break;
            }
          }
        }

        if (dropdownContentItems == null) {
          return true;
        }

        // multilevel-select component with filtering feature might be used, therefore take only the visible items
        const visibleItems = [];
        let selectedItem = null;
        // TODO once `:scope` becomes more supported, this code for finding direct child by class will not be needed
        for (i = 0; i < dropdownContentItems.length; i++) {
          if (window.getComputedStyle(dropdownContentItems[i]).display !== 'none' && dropdownContentItems[i].nodeName === 'LI') {
            visibleItems.push(dropdownContentItems[i]);
            if (dropdownContentItems[i].classList.contains(hlClass)) {

              selectedItem = visibleItems.length-1;
            }
          }
        }

        // the right arrow works only when there is no multilevel-select input and when the selected content item is also a dropdown
        if ( x === 39 &&
            selectedItem != null  &&
            (dropdownContent.querySelector('.js-multilevel-select input') || !visibleItems[selectedItem].classList.contains('js-dropdown'))
          ) {
          return true;
        }

        // enter and right arrow keys trigger click at first child
        if ( (x === 13 || x === 39) &&
            selectedItem != null
          ) {
          if (selectedItem != null) {
            if (visibleItems[selectedItem].children.length)
              visibleItems[selectedItem].children[0].click();
            else
              visibleItems[selectedItem].click();
          }

          // TODO: if the item is also a dropdown, select the first item in its content by recursive call
          return false;
        }

        // following code executes only if arrow down or up was pressed

        // remove highlight class from all content items
        dropdownContent.evToggleCleanLiHighlight();

        // if no item was selected, then arrow down selects the first item
        if (selectedItem == null && x === 1) {
          x = 0;
        }
        // highlight the next or previous item and allow overflow
        selectedItem = (selectedItem+x) % visibleItems.length;
        selectedItem = selectedItem < 0 ? visibleItems.length+selectedItem : selectedItem;
        visibleItems[selectedItem].classList.add(hlClass);

        // check if the list has limited height (multilevel-select) and the highlighted element is out of scrolled viewport
        if (dropdownContentList.scrollHeight > dropdownContentList.clientHeight) {
          const itemTopPos = visibleItems[selectedItem].offsetTop,
            listTopPos = dropdownContentList.offsetTop;
          // if the item is above visible port, scroll up and show one more previous item
          if (itemTopPos < listTopPos + dropdownContentList.scrollTop + 20) {
            dropdownContentList.scrollTop = itemTopPos - listTopPos - visibleItems[selectedItem].clientHeight;
          }
          // if the item is below visible port, scroll bown and show one more next item
          else if (itemTopPos + visibleItems[selectedItem].clientHeight > listTopPos + dropdownContentList.scrollTop + dropdownContentList.clientHeight - 20) {
            dropdownContentList.scrollTop = itemTopPos - listTopPos - dropdownContentList.clientHeight + 2*visibleItems[selectedItem].clientHeight;
          }
        }
      }

      // prevent page from scrolling based on arrow keys
      return false;
    };

    // triggering body click on every window resize to close all open dropdowns
    // this is due to the change between acordion and dropdown on mobiel and desktop for some toggles
    window.addEventListener('resize', function() {
      document.body.click();
    });

  }, false);
})
(window);
