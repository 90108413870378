window.addEventListener('load', function () {
  let mobileTrigger = document.querySelector(".js-trigger-table--force-mobile");
  let tableMobile = document.querySelector(".table-test-mobile");
  let tableDesktop = document.querySelector(".table-test-desktop");

  if (tableMobile) {
    mobileTrigger.addEventListener("click", function () {
      tableDesktop.classList.toggle("table--force-mobile");
      tableMobile.classList.toggle("table--force-mobile");
    });
  }
});
