
/**
 * Loader functions. BOR-TODO: temporary name suffix 2 until BO same name func is in usage. BOR-TODO: depends on jquery, rewrite into pure js.
 */
(function($) {
  var loaders = {};  // object-objects created loaders
  var loadersCount = {};  // object-objects count of created loaders

  $.extend({
    /**
    * Get element for loader.
    * @return object
    */
    evLoader2GetElem: function() {
      return $('<div class="ajax_loader js-loader"><div class="ajax_loader_overlay"></div><div class="ajax_loader_loader"><i class="fas fa-spinner fa-spin"></i></div></div>');
    }
  });

  /**
   * Show loader on elements.
   *
   * @param position (absolute, relative, fixed, etc)
   * @returns {jQuery}
   */
  $.fn.evLoader2Show = function(position) {
    this.each(function() {
      var htmlElem = $(this).get(0);
      if (htmlElem == window) {
        htmlElem = document.body;
      }
      if($.isNumber(loadersCount[htmlElem]) && loadersCount[htmlElem] > 0) {
        ++ loadersCount[htmlElem];
        return;
      } else {
        loadersCount[htmlElem] = 1;
      }

      var loader = $.evLoader2GetElem();
      $(loader).appendTo('body');
      var zIndex = $(htmlElem).elemZIndex();
      zIndex = zIndex>0 ? zIndex + 1 : 999;
      var maxWidth = window.scrollX + window.innerWidth - $(htmlElem).offset().left;
      var maxHeight = window.scrollY + window.innerHeight - $(htmlElem).offset().top;
      $(loader).css({
        'left': $(htmlElem).offset().left,
        'top': $(htmlElem).offset().top,
        'width': Math.min($(htmlElem).outerWidth(), maxWidth),
        'height': Math.min($(htmlElem).outerHeight(), maxHeight),
        'z-index': zIndex
      });

      if ((typeof position != 'undefined') && position != '') {
        $(loader).css({
          'position' : position
        });
      }

      loaders[htmlElem] = loader;
    });
    return this;
  };

  /**
  * Hide loader on elements.
  */
  $.fn.evLoader2Hide = function() {
    this.each(function() {
      var htmlElem = $(this).get(0);
      if (htmlElem == window) {
        htmlElem = document.body;
      }

      if($.isNumber(loadersCount[htmlElem]) && loadersCount[htmlElem] > 1) {
        -- loadersCount[htmlElem];
        return;
      } else {
        loadersCount[htmlElem] = 0;
        if($.isSet(loaders[ htmlElem ])){
          $(loaders[ htmlElem ]).remove();
        }
        delete loaders[ htmlElem ];
      }
    });
    return this;
  };

  /**
  * Hide all loaders on elements.
  */
  $.fn.evLoader2HideAll = function() {
    var htmlElem = $(this).get(0);
    if (htmlElem == window) {
      htmlElem = document.body;
    }
    if($.isSet(loaders[ htmlElem ])){
      $(loaders[ htmlElem ]).remove();
    }
    delete loaders[ htmlElem ];
    delete loadersCount[htmlElem];
  };

}(jQuery));

$(document).ready(function() {
  $('.js-loader-onclick-wrapper').on('click', '.js-loader-onclick', function () {
    $(this).closest('.js-loader-onclick-wrapper').evLoader2Show();
  });
});
