import './legacy/ev_loader.js';

/**
* @var string ajax error status
*/
var AJAX_ERROR_STATUS = 'ERROR';

/**
 * @var string ajax ok status
 */
var AJAX_OK_STATUS = 'OK';

var XHR_OK_STATUS = 200;

/**
 *
 * Ajax functions using pure js
 */
(function() {

  /**
   * parametrize js object/array into url encoded string
   * see https://stackoverflow.com/questions/25224887/plain-javascript-equivalent-of-jquery-param#25225008
   * BOR-legacy: replacing JQuery $.param() function
   * @param {Object}  params - js object to be converted
   */
  const getUrlParams = (params, keys = [], isArray = false) => {
    const p = Object.keys(params).map(key => {
      let val = params[key]

      if ("[object Object]" === Object.prototype.toString.call(val) || Array.isArray(val)) {
        if (Array.isArray(params)) {
          keys.push("")
        } else {
          keys.push(key)
        }
        return getUrlParams(val, keys, Array.isArray(val))
      } else {
        let tKey = key

        if (keys.length > 0) {
          const tKeys = isArray ? keys : [...keys, key]
          tKey = tKeys.reduce((str, k) => { return "" === str ? k : `${str}[${k}]` }, "")
        }
        if (isArray) {
          return `${ tKey }[]=${ val }`
        } else {
          return `${ tKey }=${ val }`
        }

      }
    }).join('&');

    keys.pop();
    return p;
  }

  /**
   * evAjax2 - function performing xhrRequest - replacing $.ajax, for compatibility with $.ajax
   * [https://api.jquery.com/jquery.ajax/]
   * @param {harray}  options  - options array, jquery $.ajax important options:
   *  url - The string containing url of the request.
   *  type - type of request 'GET', 'POST'  - default GET
   *  data - optional string or a plain object that send to the server with a request.
   *  dataType - expected result may be "xml", "html", "text", "script", "json", "jsonp". Can also be a string of multiple, space-separated values.
   *    - by default any (not specified) means intelligent guess.
   *  beforeSend(xhr) - function before doing request
   *  success(result, status, xhr) - callback function when a request has been completed.
   *  error(xhr, status, error) - function run if the request fails.
   *  timeout - local timeout for the request, measured in terms of milliseconds.
   *  scriptCharset: It is used to specify the charset for the request.
   *  dataFilter(data, type) - function to handle the raw response data of the XMLHttpRequest.*
   *  processData    whether convert data to url encoded string (true = default) or pass original data unchanged (e.g. object, json) when set to false
   * additional options:
   *  loaderElems  - loader selector (BOR-TODO: Remove this parameter and move logic to evAjax2Load)
   *  errorMsg - pass optional errorMsg on xhr load errors
   *  showError - show xhr response errors (except no response 0) in modal, otherwise show in console only
   */
  Object.defineProperty(Object.prototype, 'evAjax2', {
    value : function (options) {
      if (options === null || typeof options != 'object') {
        options = {};
      }
      if (!options.url)
        evModal.error(__('An error occurred while executing the command'), __('Request URL was not set.'));
      // ajax send
      var method = (options.type ? options.type : 'GET');
      var xhr = new XMLHttpRequest();
      xhr.open(method, options.url);  // proper method should be GET, POST (or PUT for json)
      // expected response data type (if none set then default text is expected by xhr)
      if (options.dataType)
        xhr.responseType = options.dataType;
      // not url encode, but stringify json
      if (typeof options.processData == 'boolean' && !options.processData) {
        try {
          var data = JSON.stringify(options.data);
          options.data = data;
          xhr.setRequestHeader('Content-Type', 'application/json');  // json-encoded data
        } catch (e) {
          // assuming form data or other
          xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');  // form-serialized data
        }
      }
      else {
        // try to url encode js object (assoc array)
        if (typeof options.data == 'object') {
          try {
            var data = getUrlParams(options.data);
            options.data = data;
          } catch (e) { }
        }
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');  // form-serialized data
      }
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');  // mandatory in symfony

      // define default error func
      // xhr error function exported as options.error
      var errorFunc = function(errorMsg, status, error) {
        if (options.loaderElems)
          $(options.loaderElems).evLoader2Hide();

        var title = __('An error occurred while executing the command');
        var text = '';
        if ((typeof errorMsg != 'undefined') && errorMsg != '') {
          text += '<b>' + errorMsg + '</b><br /><br />';
          text += '<b>' + __('If previous message does not help you with solving this error, please contact tech support with following details:') + '</b><br /><br />';
        } else {
          text += '<b class="ajax_js_options_error">' + __('Please contact tech support with following details:') + '</b><br /><br />';
        }
        text += '<b>Error Type:</b> (' + status + ') ' + (error?error:'') + '<br />';
        text += '<b>Request:</b> (' + (options.type?options.type:'') + ' ' + (options.dataType?options.dataType:'') + ') ' + options.url;

        evModal.error(title, text);
      };
      var customErrorFunc = null;
      if (typeof options.error == 'function') {
        customErrorFunc = options.error;
        options.error = function(XMLHttpRequest, textStatus, errorThrown) {
          if (customErrorFunc(XMLHttpRequest, textStatus, errorThrown) !== true) {
            errorFunc(options.errorMsg, textStatus, errorThrown);
          }
        };
      }
      else {
        options.error = function(XMLHttpRequest, textStatus, errorThrown) {
          errorFunc(options.errorMsg, textStatus, errorThrown);
        };
      }

      // the functions will load success or error same as original jquery $.ajax()
      /* handling of xhrRequest
          see https://javascript.info/xmlhttprequest */
      xhr.onload = function() {
        var responseType = xhr.getResponseHeader('Content-Type'); // that is not working reliable
        var retrieveData = xhr.response;

        if (options.dataType = 'json' || responseType == 'application/json' || responseType == 'application/x-javascript')
          try {
            retrieveData = JSON.parse(retrieveData);
          } catch (e) {}

        if (xhr.status === XHR_OK_STATUS) {
          var data = retrieveData;
          if (data && data.status == AJAX_ERROR_STATUS) {
            if (data.session_timeout) {

              evModal.error(__('Your session has timed out.'),
                __('To be able to continue in your work, please') +
                ' <a class="ev-btn" target="_blank" href="' + GLOBE.config.loginRoute + '">' + __('Login') + '</a>'); //  { onHide: function(){ window.location = GLOBE.config.loginRoute; }});

            } else if (!options.ignoreErrorStatuses) {

              var message = null;
              if ((typeof data.message != 'undefined') && data.message !== null)
                message = data.message;

              var error = null;
              if (data.errors)
                error = data.errors;
              else if (!message)
                error = __('Unknown error');

              var title = __('An error occurred while executing the command');
              var text = '<b class="ajax_js_error">' + __('Please contact tech support with following details:') + '</b><br /><br />';
              if (message) text += '<b>Message:</b> ' + message + '<br />';
              if (error) text += '<b>Error:</b> ' + error + '<br />';
              text += '<b>Request:</b> (' + (options.type ? options.type : '') + ' ' + (options.dataType ? options.dataType : '') + ') ' + options.url;

              evModal.error(title, text);
            }

            return;
          }

          if (options.loaderElems)
            $(options.loaderElems).evLoader2Hide();

          // success func run if already defined
          if (typeof options.success == 'function')
            options.success(retrieveData);
        }
        else if (xhr.status == 0 && !customErrorFunc)
          // show default error when page response not 200 and custom error function not passed
          evModal.error(__('Connection problem'), __('A connection problem has been detected. Please check your Internet connection, reload the page and try again.'));
        else
          // custom error func
          options.error(xhr, xhr.status, null);
      };

      xhr.onerror = function() {
        if (options.showError)
          options.error(xhr, xhr.status, null);
        else {
          console.log('xhr error occured, code ' + xhr.status);
          if(options.loaderElems)
            $(options.loaderElems).evLoader2Hide();
        }
      }

      xhr.send(options.data);  // data  options.data
    }
  });

  /**
   * evAjax2Load - object function performing xhrRequest, with custom error on session timeout, with loader on element.
   * Ajax xhr request is passed to evAjax function.
   * BOR-legacy: replacing $(el).evAjax()
   * @param {array}  options  - options array
   *  success        success function (success(data))
   *  url            xhr request target
   *  type           method POST (default)|GET|PUT(recommended for json)
   *  data           xhr send data (for POST|PUT requests)
   *  dataType       expected data - either 'json' or null which means text
   *  error          optional error function (passed to evAjax2 on xhr request) (error(xhr, status, error))
   *  loaderElems    loader selector other than the object itself
   *  ignoreErrorStatuses   don't process retrieve data errors, just pass data to calling function
   *  errorMsg       custom message shown on ajax data error  BOR-legacy: originally used as surplus parameter after options (rarely used e.g. evTags.js)
   *  processData    whether convert data to url encoded string (true = default) or pass original data unchanged (e.g. object, json) when set to false
  */
  // BOR-TODO: temporary name of the function suffix 2 until BO same name func is in usage
  Object.defineProperty(Object.prototype, 'evAjax2Load', {
    value : function (options) {
      if(options === null || typeof options != 'object') {
        options = {};
      }

      var loaderElems = this;
      if (options.loaderElems)
        loaderElems = document.querySelectorAll(options.loaderElems);

      $(loaderElems).evLoader2Show(); // temporarily use jquery method

      // BOR-TODO: use another variable when sending options to xhr to avoid sending unknown options?
      var ajaxOptions = { url: options.url, type: options.type, data: options.data, dataType: options.dataType, success: options.success, error: options.error, processData: options.processData, loaderElems: loaderElems, };

      return evAjax2(ajaxOptions);
    }
  });

  // Ajax - modal compatibility functions (except sendForm)
  // To be called on DOM element or separately (in that case DOM root is used)
  // Note: object property array key shorthands used for options assignment for sendForm2Ajax
  /**
   * sendForm2 - send form's data as sendData using divSelector as place where the error if occured is shown.
   * @param {string}  sendUrl
   * @param {string}  sendData  serialized or json data
   * @param {DOM selector}   divSelector - form selector
   * @param {function}       afterSuccessFunc - function processed after success reload
   * @param {DOM selector}   loaderSelector - loader will be shown on this element instead on divSelector or modal content
   */
  Object.defineProperty(Object.prototype, 'sendForm2', { value: function(sendUrl, sendData, divSelector, afterSuccessFunc, loaderSelector) {
    return sendForm2Ajax({ sendUrl, sendData, divSelector, afterSuccessFunc, loaderSelector: loaderSelector, noModal: true, });  // object property shorthand used for options assignment
  }});

  /**
   * sendModalForm2 - behaves like sendForm2 for modal. When the ajax response contains href, the modal is next reloaded with that href ajax content.
   * @param {string}  sendUrl
   * @param {string}  sendData  serialized or json data
   * @param {DOM selector}   divSelector - form selector
   * @param {DOM selector}   afterSuccessDivSelector - part of the original page where the afterSuccess reload and replace will be processed. It is effective when
   * @param {function}       afterSuccessFunc - function processed after success reload
   * @param {DOM selector}   loaderSelector - loader will be shown on this element instead on divSelector or modal content
   * @param {DOM selector|object}  targetModal - use target modal other then default
   */
  Object.defineProperty(Object.prototype, 'sendModalForm2', { value: function(sendUrl, sendData, divSelector, afterSuccessDivSelector, afterSuccessFunc, loaderSelector, targetModal) {
    return sendForm2Ajax({sendUrl, sendData, divSelector, afterSuccessDivSelector, afterSuccessFunc,
      loaderSelector: loaderSelector, targetModal,
    });
  }});

  /**
   * sendModalForm2Close - send modal's form and on success close the modal
   * @param {string}  sendUrl
   * @param {string}  sendData  serialized or json data
   * @param {DOM selector}   divSelector - form selector
   * @param {DOM selector}   afterSuccessDivSelector - part of the original page where the afterSuccess reload and replace will be processed.
   * @param {function}       afterSuccessFunc - function processed after success reload
   * @param {function}       beforeCloseFunc - function  processed before closing of the modal
   * @param {DOM selector}   loaderSelector - loader will be shown on this element instead on divSelector or modal content
   * @param {DOM selector|object}  targetModal - use target modal other then default
   */
  Object.defineProperty(Object.prototype, 'sendModalForm2Close', { value: function(sendUrl, sendData, divSelector, afterSuccessDivSelector, afterSuccessFunc, beforeCloseFunc, loaderSelector, targetModal)   {
    return sendForm2Ajax({sendUrl, sendData, divSelector, afterSuccessDivSelector, afterSuccessFunc,
      beforeCloseFunc, loaderSelector: loaderSelector, targetModal, closeModal: true,
    });
  }});

  /**
   * sendModalForm2CloseReload - send modal's form and on success close the modal and then reload the partial specified by afterSuccessDivSelector using reloadModuleUrl.
   * @param {string}  sendUrl
   * @param {string}  sendData  serialized or json data
   * @param {DOM selector}   divSelector - form selector
   * @param {string}         reloadModuleUrl - url which will be used for afterSuccess reload
   * @param {DOM selector}   afterSuccessDivSelector - part of the original page where the afterSuccess reload and replace will be processed.
   * @param {function}       afterSuccessFunc - function processed after success reload
   * @param {bool}           noReplace - don't replace content in afterSuccessDivSelector do only ajax call*
   * @param {function}       beforeCloseFunc - function  processed before closing of the modal
   * @param {DOM selector}   loaderSelector - loader will be shown on this element instead on divSelector or modal content
   * @param {string}         moduleDataType - data type for reloadModuleUrl
   * @param {DOM selector|object}  targetModal - use target modal other then default
   */
  Object.defineProperty(Object.prototype, 'sendModalForm2CloseReload', { value: function(sendUrl, sendData, divSelector, reloadModuleUrl, afterSuccessDivSelector,
      afterSuccessFunc, noReplace, beforeCloseFunc, loaderSelector, moduleDataType, targetModal) {
    return sendForm2Ajax({sendUrl, sendData, divSelector, reloadModuleUrl, afterSuccessDivSelector, afterSuccessFunc, noReplace,
      beforeCloseFunc, loaderSelector: loaderSelector, moduleDataType, targetModal, closeModal: true,
    });
  }});


  /**
   * sendForm2Ajax - send form data, then as set by options on success close particular modal and/or reload the parent page (when noModal is set
   *  only send particular form and show error message (above the selector) or OK message as SnackBar.
   *  Can be be used as common part for wrapping function:
   *   sendModalForm2CloseReload
   *   sendModalForm2Close
   *   sendModalForm2
   *   sendForm2
   *
   * BOR-legacy: Function is generalization of evSendLightboxFormCloseReload, evSendLightboxForm, evSendLightboxFormClose,
   *  evSendLightboxFormCloseReload and evSendForm.
   *
   * @param {harray} options:
   *  {string}         sendUrl
   *  {string}         sendData
   *  {DOM selector}   divSelector - form selector
   *  {string}         reloadModuleUrl
   *  {DOM selector}   afterSuccessDivSelector - part of the original page where the afterSuccess reload and replace will be processed. It is effective when
   *                     reloadModuleUrl is specified (after particular modal is closed the page part specified with this selector is reloaded with this URL)
   *  {function}       afterSuccessFunc - function processed after success reload
   *  {bool}           noReplace - don't replace original page after success reload
   *  {function}       beforeCloseFunc - function  processed before closing of the modal
   *  {DOM selector}   loaderSelector - loader will be shown on this element instead on divSelector or modal content
   *  {string}         moduleDataType - data type for success reload (default html)
   *  {DOM selector|object}  targetModal - use target modal other then default
   * Additional options:
   *  {bool}           noModal - sending form from element which doesn't need modal (as in BOR-legacy evSendForm) (mutually excluded with targetModal)
   *  {bool}           closeModal - close modal after sending
   * Global variables defined before function call:
   *  refreshUnsavedChanges ... function to take care of form containing unsaved data on parent page, to be run before page is reloaded (effective with reloadModuleUrl option only)
   * @return {void}
   */
  const sendForm2Ajax = (options) => {
    // get modal by current divSelector or by targetModal using data-id attribute
    // BOR-TODO; use function for getting modal by id
    if (typeof options.sendUrl == 'undefined') {
      console.log('send url is undefined');
      return;
    }
    else if (typeof options.sendData == 'undefined') {
      console.log('send data is undefined');
      return;
    }
    else if (typeof options.divSelector == 'undefined') {
      console.log('selector is undefined');
      return;
    }

    var objModal = null;
    if (options.noModal) {
      if (options.targetModal||options.beforeClose||options.closeModal) {
        console.log('incompatible options specified when no modal');
        return;
      }

    } else {
      // check targetModal for object of particular type evModal thus allowing direct passing, otherwise
      // assuming targetModal is DOM element or selector, if not set use divSelector for getting modal
      if (typeof options.targetModal == 'object' && options.targetModal instanceof evModal) {
        // BOR-TODO: after testing get rid of the line
        console.log('modal instance');
        var objModal = options.targetModal;
      }
      else if (options.targetModal)
        var elem = (typeof options.targetModal=='object'?options.targetModal:document.querySelector(options.targetModal));
      else if (options.divSelector)
        var elem = (typeof options.divSelector == 'object'?options.divSelector:document.querySelector(options.divSelector));
      objModal = evModal.getModalByElem(elem);

      if (!objModal) {
        console.log("modal not found for element ", elem);
        return;
      }

      // BOR-TODO: after testing comment out the line
      console.log('modal ID ' + objModal.modalIndex);

      objModal.clearMessage();
    }

    var loaderElem = null;
    if (options.loaderSelector)
      var loaderElem = (typeof options.loaderSelector == 'object'?options.loaderSelector:document.querySelector(options.loaderSelector));
    else if (objModal)
      var loaderElem = objModal.modal.querySelector('.js-modal-body__content')
    else if (options.divSelector)
      var loaderElem = (typeof options.divSelector == 'object'?options.divSelector:document.querySelector(options.divSelector));
    if (!loaderElem) {
      loaderElem = document.body;
      console.log("warning: loader element was not found", elem);
    }

    loaderElem.evAjax2Load({
      type: "POST",
      url: options.sendUrl,
      data: (options.sendData?options.sendData:{}),
      ignoreErrorStatuses: true,  // ajax error statuses handled by the success function below. Note: transport errors are still handled by evAjax error handler.
      //error: function() { console.log('error'); },
      success: function(retrieveData)
      {
          if (retrieveData.status == AJAX_ERROR_STATUS)
             objModal.addMessage(retrieveData.flash_msg||'Unknown error occured when reading data.');  // the error shouldn't happen

          else if (retrieveData.status == AJAX_OK_STATUS)
          {

              var reloaded = false;
              var reloadModuleUrl = null;
              var moduleDiv = null;
              if (!options.reloadModuleUrl) {
                if (options.afterSuccessDivSelector) {

                  // refresh afterDiv by html data (if returned)
                  var afterDiv = (typeof options.afterSuccessDivSelector=='object'?options.afterSuccessDivSelector:document.querySelector(options.afterSuccessDivSelector));
                  if (afterDiv && retrieveData.html)
                  {
                    // replace page part
                    afterDiv.outerHTML = retrieveData.html;

                    // scroll page on reload or replaced part
                    afterDiv.scrollIntoView();
                  }

                  // reload lightbox by href returned as in evSendLightboxForm
                  if (afterDiv && retrieveData.href) {
                    reloadModuleUrl = retrieveData.href;
                  }
                }
              } else
                reloadModuleUrl = options.reloadModuleUrl;

              if (options.afterSuccessDivSelector)
                moduleDiv = (typeof options.afterSuccessDivSelector=='object'?options.afterSuccessDivSelector:document.querySelector(options.afterSuccessDivSelector));
              else
                moduleDiv = (typeof options.divSelector=='object'?options.divSelector:document.querySelector(options.divSelector));

              // close the modal next show message success Snackbar
              if (options.closeModal) {
                if (typeof options.beforeCloseFunc == 'function') {
                  options.beforeCloseFunc();
                }

                objModal.close();
              }

              // reload parent page when moduleUrl option set (Close Reload modal)
              if (reloadModuleUrl && moduleDiv)
              {
                moduleDiv.evAjax2Load({
                  url: reloadModuleUrl,
                  dataType: options.moduleDataType,
                  success: function(retrieveReloadData) {
                      // show message for user's unsaved changes
                      // TODO: refactor using pure js as this is eventival JQuery workaround (unsavedchanges.js)
                      // TODO: More tests needed to check if it's still working here. Should it be before replace take place?
                      if (typeof refreshUnsavedChanges == 'function') {
                        refreshUnsavedChanges();
                      }

                      // replace original page part
                      if (!options.noReplace) {
                        // NOTE: replaceWith cannot be used for text as it expect dom document node or text content (not equivalent to JQuery replaceWith())
                        // instead outerHTML can be used which is supported by all current browsers
                        // see https://stackoverflow.com/questions/13388379/js-how-to-replace-html-element-with-another-element-text-represented-in-string
                        // https://developer.mozilla.org/en-US/docs/Web/API/Element/outerHTML
                        moduleDiv.outerHTML = retrieveReloadData;

                        // scroll page on reload or replaced part
                        moduleDiv.scrollIntoView();
                      }

                      // show message
                      Snackbar.success(retrieveData.flash_msg||__('The changes have been saved.'));
                      if (typeof options.afterSuccessFunc == 'function') {
                        options.afterSuccessFunc(retrieveData);
                      }
                    }
                  });

              }
              else
              {
                // show message
                Snackbar.success(retrieveData.flash_msg||__('The changes have been saved.'));
                if (typeof options.afterSuccessFunc == 'function') {
                  options.afterSuccessFunc(retrieveData);
                }

              }

          }

      },
    });
  }

  /**
   * qs, qsa - get selector on element
   * BOR-TODO: use it as shorthands?
   * see https://stackoverflow.com/questions/13383886/making-a-short-alias-for-document-queryselectorall
   * https://developer.mozilla.org/en-US/docs/Mozilla/JavaScript-DOM_Prototypes_in_Mozilla
   */
  Object.defineProperty(Element.prototype, 'qs', { value: function(selector) { return this.querySelector(selector); }});
  Object.defineProperty(Element.prototype, 'qsa', { value: function(selector) { return this.querySelectorAll(selector); }});
  /** qsef - bind event listener function on selector */
  Object.defineProperty(Element.prototype, 'qsef', { value: function(selector,eventType,bindFunc)
  {
    this.querySelector(selector).addEventListener(eventType,bindFunc);
  }});


  // BOR-TODO: comment out, move or remove after testing done following part
  // Test page content
  Object.defineProperty(Object.prototype, 'modalTestContent', { value: `
<div>
  <div>
  <h2>Modal tests</h2>
  <button class="btn js-test-m1">evModal.error</button>
  <button class="btn js-test-m2">evModal.confirm</button>
  <button class="btn js-test-m3">evModal.confirm <small>(extra confirm dialog)</small></button>
  <button class="btn js-test-m4">evModal.showAjax [people - BC]</button>
  <button class="btn js-test-m5">evModal.showAjax [feedback]</button>
  <button class="btn js-test-m6">Test modal w/custom buttons and js</button>
  </div>

  <div>
  <h2>Ajax tests</h2>
  <button class="btn js-test-a1">Simple ajax GET</button>
  <button class="btn js-test-a0">Simple Ajax POST(serialized data)</button>
  <button class="btn js-test-a2">Ajax POST with loader</button>
  <button class="btn js-test-a3" title="send form data only (no modal), works on person profile">Send form [people note]</button>
  <button class="btn js-test-a4" title="send form from modal, works on films">Modal send form [films]</button>
  <button class="btn js-test-a6">Simple Ajax POST console error</button>
  </div>

  <div>
  <br />
  <button class="btn js-test-a5">Modal send form [films]<small>(reload modal content)</button>
  <button class="btn js-test-a11">Modal send close [film]</button>
  <button class="btn js-test-a12">Modal send close reload [film]<small>(reload calling page/partial)</small></button>
  </div>

</div>`
  });

  /**
   * Testing modals and ajax func
   */
  Object.defineProperty(Object.prototype, 'modalTestFunctions', { value: function()
  {

    const el = document.querySelector(".js-modal-test-dialog");
    const testsModal = evModal.getModalById(el.getAttribute('data-modal_id'));
    el.addEventListener("click", function(event) {
      let act = (event.target.classList.contains('btn')) ? event.target : event.target.closest('.btn');
      if (act) {
        act = act.classList.value.replace(/^.*js-test-/, '');  //.filter(function(cl) { return (cl.matches(/js...
        console.log('action:', act);

        switch (act) {
        case "m1":
          evModal.error('test error', 'Unknown error occured, please conntact tech supp.'); break;
        case "m2":
          evModal.confirm('test question', 'Send feedback?', { buttonTextYes: 'Sure' }, function() { window.alert('clicked'); } ); break;
        case "m3":
          evModal.confirm('test question', ['Send feedback?', 'Do you really want to send the feedback?'], { buttonTextYes: 'Sure', extraConfirm: true, }, function() { window.alert('clicked'); } ); break;
        case "m4":
          // works on people
          evModal.showAjax(
            __('Business cards'),
            routePeopleContactsSummary,
            {
              dialogClass: '_select_instantly_dialog maxw40rem',
              onLoad: function () {}
            }
          );
          break;
        case "m5":
          evModal.showAjax(__('Contact Form'), routeFeedback, { dialogId: 'feedback', dialogClass: 'js-feedback-dialog',
            onLoad: feedbackFormFunctions,
          });
          break;
        case "m6":
          // Test modal w/custom buttons
          // also check utils.js, i18n functions
          if (typeof i18n == 'undefined')
            console.log('translation array not defined, button texts will be in source language');
          var buttons = '<input type="submit" value="'+__('No')+'" class="btn btn--secondary js-confirm_no" /><input type="submit" value="'+__('Yes')+'" class="btn btn--action js-confirm_yes" />';
          var modal = evModal.show(__('Test question response'), "<p>Are you hungry?</p>\n" + buttons,
            { dialogId: 'question', dialogClass: 'js-test-dialog maxw40rem',
              onLoad: function() {
                document.querySelector('.js-test-dialog .js-confirm_yes').addEventListener('click', function(event) { window.confirm("Lets' go for lunch!"); });
                document.querySelector('.js-test-dialog .js-confirm_no').addEventListener('click', function(event) { modal.close(); });
              }
            }
          );
          break;

        case "a0":
          // POST serialized data
          var formData = 'note=testing+ajax!+'+moment()+'&param2=true';
          var ajaxOptions = {url: routeFeedback, type: 'POST', data: formData, success: function(data) { window.alert('ajax return ok'); }, };
          evAjax2(ajaxOptions);
          break;
        case "a1":
          var ajaxOptions = {url: routeFeedback, type: 'GET', data: [], success: function(data) { window.alert('ajax return ok'); }, };
          evAjax2(ajaxOptions);
          break;
        case "a2":
          var ajaxOptions = {url: routeFeedback, type: 'POST', data: [], success: function(data) { window.alert('ajax return ok'); }, };
          el.querySelector(".js-modal-body__content").evAjax2Load(ajaxOptions);
          break;
        case "a3":
          // send form data only (no modal), works on person profile
          var formData = 'note=testing+ajax!+'+moment();
          sendForm2(routePeopleSaveNotePublic /*sendUrl*/, formData /*sendData*/, '.js-profile' /*divSelector*/, /*afterSuccessFunc*/ function() {
            // reload fully current page
            location.href = location.href;
          });
          break;
        case "a4":
          // send form from modal, works on films
          var filmModal = evModal.showAjax(
            __('Edit info'),
            routeFilmsEditInfo,
            {
              dialogClass: 'films_titles_modal_noclose',
              onLoad: function () {
                var el = filmModal.modal.querySelector(".js-modal-body__content");

                // save
                el.querySelector('.films_titles_modal_noclose #titles_save').addEventListener("click", function(ev) {
                  var formData = el.querySelector('form').serialize();  // pure js serialize() as defined in custom_functions
                  sendModalForm2(routeFilmsSaveInfo /*sendUrl*/, formData /*sendData*/, '#titles_edit_box' /*divSelector*/, '#titles_edit_box' /*afterSuccessDivSelector*/, /*afterSuccessFunc*/ function() {
                    console.log('saved OK');
                  });
                  return false;
                });

                // close
                filmModal.modal.querySelector(".films_titles_modal_noclose #titles_close").addEventListener("click", function(ev) {
                  filmModal.close();
                  return false;
                });
              }
            }
          );
          break;
        case "a5":
          // send and get new modal content, works on films
          var filmModal = evModal.showAjax(
            __('Edit info'),
            routeFilmsEditInfo,
            {
              dialogClass: 'films_titles_modal_noclose',
              onLoad: function () {
                var el = filmModal.modal.querySelector(".js-modal-body__content");

                // save
                el.querySelector('.films_titles_modal_noclose #titles_save').addEventListener("click", function(ev) {
                  var formData = el.querySelector('form').serialize();
                  sendModalForm2(routeTestModal /*sendUrl*/, formData /*sendData*/, '#titles_edit_box' /*divSelector*/, '#titles_edit_box' /*afterSuccessDivSelector=moduleDiv*/, /*afterSuccessFunc*/ function() {
                    console.log('saved OK');
                  });
                  return false;
                });

                // close
                filmModal.modal.querySelector(".films_titles_modal_noclose #titles_close").addEventListener("click", function(ev) {
                  filmModal.close();
                  return false;
                });
              }
            }
          );
          break;
        case "a6":
          var ajaxOptions = {url: "http://localhost/test-invalid", type: 'POST', data: [], success: function(data) { window.alert('ajax return ok'); }, };
          el.querySelector(".js-modal-body__content").evAjax2Load(ajaxOptions);
          break;


        case "a11":
          // send close, works on films
          var filmModal = evModal.showAjax(
            __('Edit info'),
            routeFilmsEditInfo,
            {
              dialogClass: 'films_titles_modal',
              onLoad: function () {
                //var el = filmModal.modal.querySelector(".js-modal-body__content");
                var el = filmModal.modal.qs(".js-modal-body__content");

                // save and close
                //using full js functions. el.querySelector('.films_titles_modal #titles_save').addEventListener("click", function(ev) {
                el.qsef('.films_titles_modal #titles_save', "click", function(ev) {
                  var formData = el.querySelector('form').serialize();
                  sendModalForm2Close(routeFilmsSaveInfo /*sendUrl*/, formData /*sendData*/, '#titles_edit_box' /*divSelector*/, '.js-profile' /*afterSuccessDivSelector*/,
                    /*afterSuccessFunc*/ function() {
                      console.log('saved OK');
                    },
                    /*beforeCloseFunc*/ function(ev) {
                      console.log('before func');
                      evModal.confirm('Really save?', 'Do you want to save the titles?', {
                        buttonTextYes: 'Yes, sure',
                        function() {
                          window.alert('clicked');
                        },
                      });
                    },
                  );

                  return false;
                });

                // close
                //using full js functions. filmModal.modal.querySelector(".films_titles_modal #titles_close").addEventListener("click", function(ev) {
                filmModal.modal.qsef(".films_titles_modal #titles_close","click", function(ev) {
                  filmModal.close();
                  return false;
                });

              }
            }
          );
          break;
        case "a12":
          // send close reload, works on films
          var filmModal = evModal.showAjax(
            __('Edit info'),
            routeFilmsEditInfo,
            {
              dialogClass: 'films_titles_modal',
              onLoad: function () {
                //var el = filmModal.modal.querySelector(".js-modal-body__content");
                var el = filmModal.modal.qs(".js-modal-body__content");

                // save and close
                //using full js functions. el.querySelector('.films_titles_modal #titles_save').addEventListener("click", function(ev) {
                el.qsef('.films_titles_modal #titles_save', "click", function(ev) {
                  var formData = el.querySelector('form').serialize();
                  sendModalForm2CloseReload(routeFilmsSaveInfo /*sendUrl*/, formData /*sendData*/, '#titles_edit_box' /*divSelector*/, routeFilmsProfile /*reloadModuleUrl*/,
                    '.js-profile' /*afterSuccessDivSelector*/, /*afterSuccessFunc*/ function() {
                      // close underlying test modal
                      testsModal.close();
                      // run functions on reloaded parent part
                      indexFunctions();
                      $('#rcol .bg_btm').last().remove();
                  });

                  return false;
                });

                // close
                //using full js functions. filmModal.modal.querySelector(".films_titles_modal #titles_close").addEventListener("click", function(ev) {
                filmModal.modal.qsef(".films_titles_modal #titles_close","click", function(ev) {
                  filmModal.close();
                  return false;
                });

              }
            }
          );
          break;

        // TODO:

        default:
          evModal.error('test error', 'Unknown/not yet configured test clicked.');
        }
      }

    }, false);

    if (typeof window.evDevEnv != 'undefined' && window.evDevEnv)
      console.log('test functions');
  }});

  if (typeof window.evDevEnv != 'undefined' && window.evDevEnv)
    console.log('ajax functions initialized');
}());

