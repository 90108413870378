/** JS exportable functions for the flatpickr.js plugin customization.
 * Flatpicker plugin handles date and time picking, for more information see https://flatpickr.js.org/.
 * User date/time formatting is set using global variable datepickerDateTimeFormat, which should be defined prior inititazing datepicker. If not default values will be used.
 * BOR-TODO: current VP functionality not tested.
 */

// we import flatpickr here, because we depend on it
import flatpickr from "flatpickr";

export default ( function( window, document, undefined ) {

  "use strict";

  // check that global date/time variable is set and check it is object type and not empty, otherwise assign default date/time (Europe continental date with 24h time)
  if (typeof window.datepickerDateTimeFormat == 'undefined')  {
    var datepickerDateTimeFormat = { 'date':'j.n.Y', 'time':'H:i', };
  }
  else if (typeof window.datepickerDateTimeFormat != 'object' ||
      typeof window.datepickerDateTimeFormat.date == 'undefined' || typeof window.datepickerDateTimeFormat.time == 'undefined' ) {
    var datepickerDateTimeFormat = { 'date':'j.n.Y', 'time':'H:i', };
  } else
    var datepickerDateTimeFormat = window.datepickerDateTimeFormat

  /**
   * customize date/time formats
   * @param dateFormat   new format or null for no change
   * @param timeFormat   """
   */
  function setDateTimeFormat(dateFormat, timeFormat) {
    if (dateFormat)
      datepickerDateTimeFormat.date = dateFormat;
    if (timeFormat)
      datepickerDateTimeFormat.time = timeFormat;
  }

  /**
   * bind single datepicker widget (date and time-picker)
   * @param {DOM}     el   dom element under which to bind
   */
  function bind(el, options) {
    if(!el)
      return;
    if (!el.classList.contains('js-datepicker')) {
      console.log('datepicker wrapper not set in element');
      return;
    }
    if (el.classList.contains('js-bound')) {
      console.log('datepicker wrapper already bound');
      return;
    }

    if (!options)
      options = {};
    // use options, user date/time format or simple default format, flatpickr equivalent of strftime 'd.m.Y HH:MM'
    // Note: according to flatpickr manual - formatting character G - Hours, 2 digits with leading zeros 1 to 12
    if (!options.fmtDate)
      options.fmtDate = datepickerDateTimeFormat.date;
    if (!options.fmtTime)
      options.fmtTime = datepickerDateTimeFormat.time;
    var time24h = !(options.fmtTime.match(/K/));

    var settings = {
      dateFormat: options.fmtDate,  // user's date format
      wrap: true,         // wrap with buttons (wrap element must exist)
      enableTime: false,  // without time
      locale: {
        firstDayOfWeek: 1
      }, // start with monday
      allowInput: true,   // allow user change date input
      onClose(values, dateStr, inst) {
        // revalidate values on close
        inst.setDate(inst.input.value, true, inst.config.dateFormat);
      },
      altInput: false,
      time_24hr: time24h,
      minuteIncrement: 1,
    };


    // Binding widget variants
    // Note: only flatpickr inputs inside wrapper may be bound, because option 'wrap: true' is used.

    // date (date part)
    flatpickr(el.querySelectorAll(".js-datepicker--date"), Object.assign({}, settings,
      {
        minDate: el.querySelector(".js-datepicker--date").dataset.min_date,
        maxDate: el.querySelector(".js-datepicker--date").dataset.max_date,
      })
    );

    // multiple dates delimited
    flatpickr(el.querySelectorAll(".js-datepicker--multi"), Object.assign({}, settings,
      {
        mode: "multiple",
      })
    );

    // date range
    flatpickr(el.querySelectorAll(".js-datepicker--range"), Object.assign({}, settings,
      {
        dateFormat: options.fmtDate,
        mode: "range",
      })
    );

    // time (time part)
    flatpickr(el.querySelectorAll(".js-datepicker--time"), Object.assign({}, settings,
      {
        dateFormat: options.fmtTime,
        enableTime: true,
        noCalendar: true,
        onOpen(values, dateStr, inst) {
          // fill the timepicker widget with the value from the input (in case of user write into input)
          inst.setDate(inst.input.value, true, inst.config.dateFormat);
        },
        onClose(values, dateStr, inst) {
          // revalidate values on close - assure time value is always 4 digits
          inst.input.value = inst.input.value.replace(/^([0-9]:)/, '0$1');
        },
      })
    );

    // date and time combined in single input
    flatpickr(el.querySelectorAll(".js-datepicker--date-time"), Object.assign({}, settings,
      {
        dateFormat: options.fmtDate + ' ' + options.fmtTime,
        enableTime: true,
        onClose(values, dateStr, inst) {
          // revalidate values on close - assure time value is always 4 digits
          inst.input.value = inst.input.value.replace(/\s([0-9]:[0-9][0-9])/, ' 0$1');
        },
      })
    )

    // additional binding such as automatic open timepicker
    // Note: enabling blur event listener in flatpickr itself would currently need to change flatpickr sources see https://github.com/flatpickr/flatpickr/issues/1551.
    // Because of the blur bug using additional event change binding here.
    var input = el.querySelector('.js-datepicker--date input');
    if (input) {
      // when date is changed go to time widget automatically and open timepicker
      input.addEventListener("change", function(ev) {
        ev.stopPropagation();
        var parent = ev.target.closest('.js-datepicker');
        if (parent) {
          var eld = parent.querySelector('.js-datepicker--date input');
          var elt = parent.querySelector('.js-datepicker--time input');
          // focus on time widget when the event was invoked on date widget
          if (eld.value && elt) {
            elt.focus();
            elt.click();
          }

        }
      });
    }

    el.classList.add('js-bound');
  };

  /**
   * bind all datepickers under element or document body (default)
   * @param {DOM}     el   dom element under which to bind (default document body)
   * @param {array}   options  options may be:
   *                    includeHiddens -  whether include hidden(invisible) elements (default false)
   *                    fmtDate, fmtDate - optional date, time formating other than global (datepickerDateTimeFormat) or default (in flatpickr format)
   * @param {customBindSelector}   only bind custom datepickers containing additional selector such as class (also for future dynamic elements) together with js-bind-custom class. Default null means normal bind.
   */
  function bindAll(el, options, customBindSelector) {
    if(!el)
      el = document.body;
    if (!options)
      options = {};

    const datepickerSelector = '.js-datepicker:not(.js-datepicker--readonly):not(.js-bound)'+( customBindSelector ? '.js-bind-custom'+customBindSelector : ':not(.js-bind-custom)');

    // walk current DOM array (walk now and as callback function for added elements)
    function walkBindNodes(els) {
      if (els && typeof els.length == 'undefined')
        els = [ els ];
      //console.log('datepicker re/bind, num elems '+els.length, els);
      for (var j = 0; j < els.length; j++)  {
        // don't bind invisible elements (nested form prototypes), don't bind element outside path
        if ( (!options.includeHiddens && !(els[j].offsetWidth || els[j].offsetHeight || els[j].getClientRects().length)) || ! el.contains(els[j]) ) {
          //console.log('not bound #'+j);
          continue;
        }

        bind(els[j], options);
      }
    }
    walkBindNodes(el.querySelectorAll(datepickerSelector));

    // use the MutationObserver to bind future elements
    document.observeAddedNodes(datepickerSelector, walkBindNodes);
  };

  /**
   * set particular date in custom format
   * Sent DOM element must be date widget only.
   * @param {DOM} el - element under which is the flatpickr date input
   * @param string value - new date value
   * @param string valueFormat - date value format
   */
  function setDate (el, value, valueFormat) {
    if (!el || !el.classList.contains('js-datepicker') || !el.querySelector('.js-datepicker--date'))  {
      console.log('setDate element is not datepicker date widget');
      return;
    }
    var fp = flatpickr(el.querySelector('.js-datepicker--date input'));
    fp.setDate(value, false, 'Y-m-d'); // see https://flatpickr.js.org/instance-methods-properties-elements/
    fp.set('dateFormat', datepickerDateTimeFormat.date);  // force datepicker to revalidate new date by user format
  };

  /**
   * get Date object from the date widget instance
   * Sent DOM element must be date widget only.
   * @param {DOM} el - element under which is the flatpickr date input
   * @returns {Date|null}
   */
  function getDate (el) {
    if (!el || !el.classList.contains('js-datepicker') || !el.querySelector('.js-datepicker--date'))  {
      console.log('setDate element is not datepicker date widget');
      return null;
    }
    var fp = el.querySelector('.js-datepicker--date')._flatpickr;

    if (typeof fp === "undefined" || !fp.selectedDates.length) {
      return null;
    }

    // get the first element without removing it from the array (that's what would shft() do)
    return new Date((fp.selectedDates.find(e => true)));
  };

  /**
   * get formatted date from the date widget instance, given Date object
   * Sent DOM element must be date widget only.
   * @param {DOM}  el - element under which is the flatpickr date input
   * @param {Date} date
   * @returns {String|null}
   */
  function formatDate (el, date) {
    if (!el || !el.classList.contains('js-datepicker') || !el.querySelector('.js-datepicker--date'))  {
      console.log('setDate element is not datepicker date widget');
      return null;
    }
    var fp = el.querySelector('.js-datepicker--date')._flatpickr;

    if (typeof fp === "undefined") {
      return null;
    }

    const dateFormat = fp.config.dateFormat;

    return fp.formatDate(date, dateFormat);
  };

  // revealing pattern
  // https://addyosmani.com/resources/essentialjsdesignpatterns/book/#revealingmodulepatternjavascript
  var evDatepicker = {
    bind: bind,
    bindAll: bindAll,
    setDate: setDate,
    setDateTimeFormat: setDateTimeFormat,
    getDate: getDate,
    formatDate: formatDate,
  };

  return evDatepicker;

})( window, document );
