/**
 * Editing of tags.
 * @author Michal Kandr
 */
(function($){
  /**
   * Initialize tag editing on element.
   * Element must contain input box with tags.
   *
   * @param options Object options for plug-in:
   *    onlyEdit boolean - (default false) if set to true , plug-in allows only editing in form - no AJAX saving and no icons handling.
   *    onlyFromValues boolean - (default false) If set to true, the user can only pick values from the suggestions.
   *    minChars integer - minimum chars for showing autocomplete
   *    saveError string - error text shown when error occured in saving operation, required
   *    saveUrl string - url for saving tags. Tags are send in 'tags' parameter in json format (array of tag names). required
   *    autocompleteUrl string - url for getting tags for autocompletion. Send typed string in 'search' parameter and expecting json array with suitale tags in 'items' property. required.
   *      Default is '' which means no url change (autocomplete search parameter is sent with current url) - use null to turn autocomplete search off.
   *    showSelect boolean - show select box with all posible values, tags can be added by selecting from this select. Defaul false.
   *    selectOnly boolean - values can be added obly by selecting from select, will be ignored when showSelect=false. default false
   *    selectElem string|object - HTML select input (jQuery object or string selector) with posible values. Apply only with showSelect=true. Values wouldn't be loaded by ajax, instead this select will be used.
   *    selectDefaultValueKeys - should the "val" attributes be taken instead of text when initialising the select box?
   *    followSelectOptionsOrder boolean - if FALSE then set order of tag values as it is in the select element (Apply only with showSelect=true). If TRUE then tag values are ordered by original order (default).
   *    TBLOptions object - additional (custom) configuration for TextboxList
   *    TBLEvents object - event handlers for  TextboxList {event_name: function, ...}
   *
   *    evSearchPrefix - [bool/string] (related to $.evSearchBox())
   *      - false: don't make it searchable,
   *      - true: trigger search directly for current values (default)
   *      - string: search for string prefixed (eg. prefix: 't', value: 'test' => search: 't:test')
   */
  $.fn.evTags = function(options){
    // debugging execution time
    if (GLOBE.config.devel) {
      var d = new Date();
      var time1 = d.getSeconds()*1000 + d.getMilliseconds();
    }
    /**
     * @var object settings
     */
    var settings = {
      onlyEdit: false,
      onlyFromValues: false,
      minChars: 2,
      saveError: '',
      saveUrl: '',
      autocompleteUrl: '',
      showSelect: false,
      selectOnly: false,
      selectElem: false,
      selectDefaultValueKeys: false,
      followSelectOptionsOrder: true,
      selectiveSelectElem: false,
      selectiveSelectElem2: false,
      draggable: false,
      TBLOptions: null,
      TBLEvents: null,
      evSearchPrefix: false,
      evSearchEntity: null,
      evOnClick : 'search'
    };
    $.extend(settings, options);
    /**
     * Disable focusing of noneditable bit.
     * @param bit object
     */
    function disableBitFocus(bit){
      bit.blur();
    }
    /**
     * Disable focusing of editable bit.
     * @param bit object
     */
    function disableBitEditableFocus(bit){
      bit.hide();
    }

    $(this).each(function(){
      /**
      * @var object original tags
      */
      var oldTags = null;
      /**
       * @var object textboxlist instance
       */
      var tbl = null;

      var tagElem = this;

      /**
       * @var object div with autocomplete
       */
      var autocompleteDiv = null;

      /**
       * Enable editing of tags.
       * @return void
       */
      var enableEditing = function(){
        $('.textboxlist-bit-box-deletable-maybe', tagElem).addClass('textboxlist-bit-box-deletable');
        $('.textboxlist-bit-box-deletebutton', tagElem).show();
        $('.textboxlist-bit-editable:last', tagElem).show();
        tbl.removeEvent('bitFocus', disableBitFocus);
        tbl.removeEvent('bitEditableFocus', disableBitEditableFocus);
        autocompleteDiv.appendTo(tbl.getContainer());
        if(settings.evSearchPrefix !== false) {// && !(settings.draggable || tagElem.className.indexOf('ev-tag-draggable') != -1)) {
          tbl.removeEvent('bitFocus', sendToSearchBox);
        }
        $('.textboxlist-bit-editable:last input', tagElem).focus();
      };

      /**
       * send tagged entities to searchBox
       */
      var sendToSearchBox = function(bit){
        //console.log('tag send to search');
        if(bit.value !== null){
          var prefix = '';
          if($.isString(settings.evSearchPrefix)) {
            prefix = settings.evSearchPrefix;
          }
          if ((typeof bit.value[3] != 'undefined') && (bit.value[3] == 'staticTag')){
            return null;
          }
          if(settings.evOnClick == 'search'){
            $.evSearchBox.search(prefix+bit.value[1].replace(/^\s*((?:[\S\s]*\S)?)\s*$/, '$1'), true, true);  // searching by exact name (correspondant with changes in evSearchRoutines from LIKE to EQUAL). Note by VD.
            if (settings.evSearchEntity !== null) {
              $('#search_types_'+settings.evSearchEntity+':not(.slc)').click();
            }
          } else if ((settings.evOnClick == 'href') && (typeof bit.value[3] != 'undefined') && (bit.value[3] != 'staticTag')){
            window.open(bit.value[3]);
          }
        }
      };

      /**
       * Disable editing of tags.
       * @param boolean onlyAdding only adding tags will be disabled - funkcionality for deleting is preserved
       * @return void
       */
      var disableEditing = function(onlyAdding){
        if( ! onlyAdding){
          $('.textboxlist-bit-box-deletebutton', tagElem).hide();
        }
        $('.textboxlist-bit-editable', tagElem).hide();
        $('.textboxlist-bit-box-deletable', tagElem).addClass('textboxlist-bit-box-deletable-maybe').removeClass('textboxlist-bit-box-deletable');
        tbl.addEvent('bitFocus', disableBitFocus);
        tbl.addEvent('bitEditableFocus', disableBitEditableFocus);
        autocompleteDiv = $('.textboxlist-autocomplete', tagElem);
        autocompleteDiv.remove();
        if(settings.evSearchPrefix !== false) {// && !(settings.draggable || tagElem.className.indexOf('ev-tag-draggable') != -1)) {
          tbl.addEvent('bitFocus', sendToSearchBox);
        }
      };

      /**
       * Function is run after dragging (reordering) tag is completed but before click event is processed.
       * To disable click event the bitFocus class is removed temporarily and added back with minimum timeout avoid processing click.
       * (functions enableEditing and disableEditing automatically add class bitFocus when evSearchPrefix option is not false).
       */
      function tagsSortableReordered(event, ui) {
        tbl.removeEvent('bitFocus', sendToSearchBox);
        //console.log('tag dragged');
        var separator = GLOBE.config.tag_separator;
        $('input', tagElem).attr('value', '');
        $('.textboxlist-bit.textboxlist-bit-box', tagElem).each(function() {
          var text = $(this).text()
          //console.log('added ' + text);
          var val = $($('input', tagElem)).attr('value');
          $('input', tagElem).attr(
            'value', (val == '' ? '' : val + separator ) + text
          );
        });
        tbl.update();

        if (typeof $.refreshUnsavedChanges != 'undefined') {
          $('input', tagElem).refreshUnsavedChanges();
        }

        setTimeout(function () {
          //console.log('activating click');
          tbl.addEvent('bitFocus', sendToSearchBox);
          }, 500);
      }

      var bindTagsSortable = function() {
        //console.log('sortable tags initialized');
        $('.textboxlist-bits .textboxlist-bit.textboxlist-bit-box', tagElem).addClass('textboxlist-bit-sortable');
        $('.textboxlist-bits', tagElem).sortable({
          forcePlaceholderSize: true,
          placeholder: 'textboxlist-bit-placeholder',
          update: function( event, ui ) { tagsSortableReordered(event, ui); },
          // other events are start:, stop:, deactivate: which are bind to user dragging the element
        });
      }

      /**
       * Start editing of tags.
       * @param object elem element with input
       * @return void
       **/
      var edit = function(elem){
        oldTags = tbl.getValues();
        enableEditing();
        return true;
      };
      /**
       * Save tags.
       * @param object elem element with input
       * @return void
       **/
      var save = function(elem){
        var tags = [];
        for(var i=0, values = tbl.getValues(); i<values.length; ++i){
          tags.push(values[i][1]);
        }
        $(tbl.getContainer()).evAjax({
          url: settings.saveUrl,
          type: 'POST',
          data: {
            tags: JSON.stringify(tags)
          },
          dataType: 'json',
          success: function(data){
            disableEditing();
            $(elem).evEditIconsSave();
          }
        }, settings.saveError);

        if (typeof $.refreshUnsavedChanges !== 'undefined') {
          $($('input', tagElem)).refreshUnsavedChanges();
        }
      };
      /**
       * Cancel editing of tags.
       * @param object elem element with input
       * @return void
       **/
      var cancel = function(elem){
        $('.textboxlist-bit-box-deletebutton', elem).click();
        tbl.setOptions({unique: false});
        tbl.setValues(oldTags);
        tbl.setOptions({unique: true});
        disableEditing();
        return true;
      };

      if ( ! settings.onlyEdit) {
        // inicialize edit
        $(this).evEditIcons(edit, save, cancel);
      }

      // inserting by select - processing of existing values
      if(settings.showSelect && (settings.autocompleteUrl || settings.selectElem)){
        var selectDefaultValue = $('input', this).val();
        $('input', this).val('');
        selectDefaultValue = selectDefaultValue ? selectDefaultValue.split(GLOBE.config.tag_separator) : [];
      }

      var tblConfig = {
        unique: true,
        bitsOptions: {
          editable: {
            addOnBlur: true
          },
          box: {
            searchButton: false
          }
        },
        // using custom tag_separator instead of default comma
        encode: function(o){
          return $.grep($.map(o, function(v){
            v = ( (!!(v[0] || v[0] === 0) ) ? v[0] : v[1]);
            return (!!(v[0] || v[0] === 0) ) ? v.toString().replace(GLOBE.config.tag_separator, '') : null;
          }), function(o){ return typeof o != 'undefined'; }).join(GLOBE.config.tag_separator);
        },
        decode: function(o){ if (!o) return []; return o.split(GLOBE.config.tag_separator); },
      };

      if (settings.autocompleteUrl !== null) {
        tblConfig['plugins'] = {
          autocomplete: {
            minLength: settings.minChars,
            queryRemote: true,
            onlyFromValues: settings.onlyFromValues,
            remote: {
              url: settings.autocompleteUrl
            }
          }
        };
      }

      if((settings.TBLOptions !== null) && ($.isObject(settings.TBLOptions))){
        $.extend(tblConfig, settings.TBLOptions);
      }

      if (settings.evSearchPrefix !== false) {
        $.extend(true, tblConfig, {
          bitsOptions: {
            box: {
              searchButton: true
            }
          }
        });
      }

      tbl = new $.TextboxList($('input', this), tblConfig);

      if(settings.TBLEvents !== null && $.isObject(settings.TBLEvents)){
        for(var i in settings.TBLEvents){
          if($.isFunction(settings.TBLEvents[i])){
            tbl.addEvent(i, settings.TBLEvents[i]);
          }
        }
      }

      if( ! settings.onlyEdit){
        oldTags = tbl.getValues();
      }

      if ( ! settings.onlyEdit || (settings.showSelect && settings.selectOnly)) {
        disableEditing((settings.showSelect && settings.selectOnly));
      }

      // show select box if set
      if(settings.showSelect && (settings.autocompleteUrl || settings.selectElem)){
        /**
         * bind events on select and tag delete
         * @param Object selectElem select tag element
         */
        var initializeSelectList = function(selectElem){
          var hideItem = function(val, item){
            var dropdown = $(selectElem).dropDownGetOwner();
            if(dropdown){
              dropdown.dropDownHideItem(val);
            } else {
              if( ! item){
                item = $('option:selected', selectElem);
              }
              $(item).hide();
            }
            if( ! $('option[value=""]', selectElem).length){
              $(selectElem).val('').change();
            }
          };
          $(selectElem).change(function(){
            var selected = $('option:selected', this);
            var val = $(this).val();
            if (val) {
              if ($.isString(settings.selectiveSelectElem) || $.isJQuery(settings.selectiveSelectElem)) {
                var selectiveSelect = $.isJQuery(settings.selectiveSelectElem) ? settings.selectiveSelectElem : $(settings.selectiveSelectElem);

                var selectiveSelected = $('option:selected', selectiveSelect);
                if ($.isString(settings.selectiveSelectElem2) || $.isJQuery(settings.selectiveSelectElem2)) {
                  var selectiveSelect2 = $.isJQuery(settings.selectiveSelectElem2) ? settings.selectiveSelectElem2 : $(settings.selectiveSelectElem2);

                  var selectiveSelected2 = $('option:selected', selectiveSelect2);

                  if (selectiveSelected2.val()) {
                    tbl.add(selected.text(), val, selectiveSelected.text()+': '+selectiveSelected2.text()+': '+selected.text());
                    hideItem(val, selected);
                  }
                } else if (selectiveSelected.val()) {
                  tbl.add(selected.text(), val, selectiveSelected.text()+': '+selected.text());
                  hideItem(val, selected);
                }
              } else {
                tbl.add(selected.text(), val);
                hideItem(val, selected);
              }
            }
            $(this).val('');
          });

          if(settings.search2link) {
            $('.js-add-reviewer, .js-add-driver, .js-add-jury').search2link({
              restrict: 'evPerson',
              onLink: function (entity, id, name, data) {
                tbl.add(data.name, data.id.toString());
              }
            });
          }

          tbl.addEvent('bitRemove', function(bit){
            var dropdown = $(selectElem).dropDownGetOwner();
            if(dropdown){
              dropdown.dropDownShowItem(bit.value[0]);
            } else {
              $('option[value=' + bit.value[0] + ']', selectElem).show();
            }
          });
          var values = tbl.getValues();
          for(var i=0; i<values.length; ++i){
            hideItem(values[i][0]);
          }
          // either when option draggable either class 'ev-tag-draggable' is set, then tags can be reorder by dragging
          if(settings.evSearchPrefix !== false) {
            //console.log(' tags draggable ');
            $('.textboxlist-bits .textboxlist-bit.textboxlist-bit-box', tagElem).addClass('textboxlist-bit-searchable');
          }
          // either when option draggable either class 'ev-tag-draggable' is set, then tags can be reorder by dragging
          if (settings.draggable || tagElem.className.indexOf('ev-tag-draggable') != -1) {
            //console.log(' tags draggable ');
            bindTagsSortable();
          }
        };

        // loading list by AJAX
        if (settings.selectElem === false) {
          $(this).evAjax({
            url: settings.autocompleteUrl,
            type: 'POST',
            data: {
              search: ''
            },
            dataType: 'json',
            success: function(data){
              var select = '<select><option value=""></option>';
              for (var i = 0; i < data.length; ++i) {
                // TODO: if the data contain string with diacritics, then suggestion is not shown
                var defaultIndex = $.inArray(data[i][(settings.selectDefaultValueKeys ? 0 : 1)], selectDefaultValue);
                if (defaultIndex >= 0) {
                  tbl.add(data[i][1], data[i][0]);
                }
                select += '<option value="' + data[i][0] + '"' + (defaultIndex >= 0 ? 'style="display: none;"' : '') + '>' + data[i][1] + '</option>';
              }
              select += '</select>';
              var selectElem = $(select);
              $(tbl.getContainer()).after(selectElem);

              initializeSelectList(selectElem);
            }
          });
        // list is allready defined in HTML code
        } else if($.isString(settings.selectElem) || $.isJQuery(settings.selectElem)){
          var select = $.isJQuery(settings.selectElem) ? settings.selectElem : $(settings.selectElem);
          if (select) {
            if( ! $('option[value=""]', select).length){
              $(select).prepend('<option value=""></option>');
            }

            var optionElements = new Object();
            var optionIndex = 0;
            var hasSelectiveSelectElem = $.isString(settings.selectiveSelectElem) || $.isJQuery(settings.selectiveSelectElem);

            if (hasSelectiveSelectElem) {
              var extendedDefaultValue = [];
              var shortenedDefaultValue = [];
              for (var i = 0; i < selectDefaultValue.length; ++i) {
                var splitValue = selectDefaultValue[i].split(': ');
                var shortenedValue = splitValue.pop();
                extendedDefaultValue[shortenedValue] = selectDefaultValue[i];
                shortenedDefaultValue[i] = shortenedValue;
              }
            }

            var orderByAlpha = settings.followSelectOptionsOrder;
            if (orderByAlpha && tagElem.className.indexOf('ev-tag-follow-select-options-order') == -1)
            {
              orderByAlpha = false;
              //console.log(' tags order by id ');
            }

            if (orderByAlpha) {
              $('option', select).each(function(){
                if ($.inArray((settings.selectDefaultValueKeys ? $(this).attr('value') : $(this).text()), hasSelectiveSelectElem ? shortenedDefaultValue : selectDefaultValue) >= 0) {
                  optionElements[optionIndex++] = $(this);
                }
              });
            } else {
              var val = null;
              for (var i = 0; i < selectDefaultValue.length; ++i) {
                val = hasSelectiveSelectElem ? shortenedDefaultValue[i] : selectDefaultValue[i];
                $('option', select).each(function(){
                  if ((settings.selectDefaultValueKeys ? $(this).attr('value') : $(this).text()) == val) {
                    optionElements[optionIndex++] = $(this);
                  }
                });
              }
            }

            for (var i in optionElements) {
              var optionToAdd = optionElements[i];
              optionToAdd.hide();
              if (hasSelectiveSelectElem) {
                tbl.add(optionToAdd.text(), optionToAdd.attr('value'), extendedDefaultValue[optionToAdd.text()]);
              } else {
                tbl.add(optionToAdd.text(), optionToAdd.attr('value'));
              }
            }

            initializeSelectList(select);
          }
        }
      }

      $('.ev-tags-prebind', tagElem).hide();

      if (typeof $.refreshUnsavedChanges !== 'undefined') {
        $($('input', tagElem)).refreshUnsavedChanges();
      }
    });

    if (GLOBE.config.devel) {
      var d = new Date();
      var time2 = d.getSeconds()*1000 + d.getMilliseconds();
    }

    return this;
  };
})(jQuery);
