// mobile navigation handler
export default  {
  start: function start() {
    const nav = document.querySelector("#global-nav")
    , navToggle = document.querySelector(".mobile-nav-toggle")

    if (nav && navToggle) {
      navToggle.addEventListener("click", function(e){
        nav.classList.toggle('is-open');
        navToggle.classList.toggle('is-open');
        e.preventDefault();
      }, false)
    }

    if (typeof window.evDevEnv != 'undefined' && window.evDevEnv)
      console.log("Navigation initilized.");
  }
};

