/**
 * General page script
 */
// common functions currently require jQuery. We'll try to create a pure JS component in the future
import '../vendor/jQuery/jquery.min.js';
import '../vendor/jQuery/jquery-ui.custom.min.js';
import '../base/legacy/utils.js';
import '../base/common_functions.js';
import '../base/loaded.js';
import Snackbar from '../components/snackbar.js';
import search from '../apps/styleguide/algolia_search.js';
import copy_code from '../apps/styleguide/copy-code.js';
import navigation from  '../apps/styleguide/navigation.js';
import evModal from '../components/ev_modal.js';
import '../apps/styleguide/table_triggers.js';
import evDatepicker from '../components/ev_datepicker.js';
import '../vendor/dropzone.js';
import '../components/tabs.js';
import '../components/table.js';
import '../apps/styleguide/toggle-menu.js';
import '../apps/styleguide/toggle-code.js';
import '../components/toggle.js';
import '../components/multilevel-select.js';
import '../components/select-box.js';
import '../vendor/drooltip.js';

// tags component currently requires jQuery. We'll try to create a pure JS component in the future
import '../vendor/jQuery/TextboxList.js';
import '../components/legacy/tags.js';

import '../components/ev_ajax.js';
// import '../components/ev_datepicker.js';
import '../apps/styleguide/modal_trigger.js';

// run everything once we are fully loaded

window.addEventListener('load', function() {
  var tooltip = new Drooltip({
    "element" : ".js-tooltip.test-tooltip",
    "trigger" : "hover",
    "position" : "left",
    "background" : "#2175ff",
    "color" : "#fff",
    "animation": "bounce",
    "content" : '<p class="tooltip-content">This is test tooltip content.</p>',
    "callback" : null
  });

  $(function() {
    window.Snackbar = Snackbar;
    window.evModal = evModal;
    window.evDatepicker = evDatepicker;
    // bind all current and future datepickers
    evDatepicker.bindAll();
    // datepicker(s) with special time forma
    evDatepicker.bindAll(null, { fmtTime: 'G:i K' }, '.js-time-12');
    evDatepicker.bindAll(null, { fmtTime: 'H:i' }, '.js-time-24');
    search.start();
    navigation.start();
    copy_code.start();

    if (typeof window.evDevEnv != 'undefined' && window.evDevEnv)
      console.log("Eventival initialized.");
  });
}, false);

