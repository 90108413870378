const AJAX_OK_STATUS = 'OK';
const AJAX_ERROR_STATUS = 'ERROR';

const dialogId = 'jqm_dialog_wrapper';
const dialogClass = 'jqm_dialog';
const errorId = 'jqm_error_wrapper';
const confirmId = 'jqm_confirm_wrapper';
const confirmClass = 'jqm_confirm';

/**
 * var integer count of actually shown dialogs
 * globbaly visible because it's used by the legacy version of modal (dialog) too
 */
window.shownDialogs = window.shownDialogs || 0;

const evModal = (() => {
  'use strict';

  // TODO; usable in future for focusing dialog elements (left from micromodal)
  const FOCUSABLE_ELEMENTS = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
    'select:not([disabled]):not([aria-hidden])',
    'textarea:not([disabled]):not([aria-hidden])',
    'button:not([disabled]):not([aria-hidden])',
    'iframe',
    'object',
    'embed',
    '[contenteditable]',
    '[tabindex]:not([tabindex^="-"])'
  ];

  /**
   * unique array of modals
   */
  var modals = {};

  /**
   * evModalClass prototype
   */
  class evModalClass {
    constructor ({
      options = {},
      onLoad = () => { },
      onClose = () => { },
      debugMode = false,
    }) {
      this.modal = null;

      this.backdrop = null;

      this.modalIndex = null;

      // this part is refactored after old dialog, but the jqmOnHide and dialogCheckResponseOnLoad were removed
      // dialogCheckResponseOnLoad is processed inside evAjax2 and evAjax2Load
      // jqmOnHide is no more used because we no more rely on JQuery
      if (options.onClose && typeof options.onClose == 'function'){
        onClose = options.onClose;
      } else if (options.onHide && typeof options.onHide == 'function'){
        onClose = options.onHide;
      }
      if (options.onLoad && typeof options.onLoad == 'function'){
        onLoad = options.onLoad;
      } else if (options.onShow && typeof options.onShow == 'function'){
        onLoad = options.onShow;
      }

      // Save a reference to the passed config
      this.config = { options, onLoad, onClose, debugMode };

      // handling clearing of and adding to js-modal-message__content
      this.messageToggle = null;
      this.messageContent = null;

      // The modal is shown right from constructor
      this.show();
    }

    /**
     * Show modal - render modal dialog, store reference to modals array(can be got by calling getModalById())
     *
     * Not supposed to be called directly.
     * Supposed to be called once per dialog e.g. from constructor.
     */
    show () {
      window.shownDialogs++;
      this.modalIndex = 4000 + window.shownDialogs * 2;
      var overlayIndex = 4000 + window.shownDialogs * 2 - 1;
      var options = this.config.options;

      var modal = document.createElement('div');
      modal.innerHTML =
		'<div class="modal-heading">' +
		  '<h3>' + ( options.title ? options.title : '') + '</h3>' +
		  '<div class="modal-heading__actions">' +
			'<div class="js-toggle toggle js-modal-message-toggle modal-message-trigger active dn" data-toggle_target_id="feedback-form-message-' + this.modalIndex + '"><i class="fas fa-lg fa-exclamation-circle"></i></div>' +
			'<div class="js-modal-close"><i class="fas fa-times-circle fa-lg"></i></div>' +
		  '</div>' +
		'</div>' +
		'<div class="modal-message js-modal-message toggle-content js-toggle-content" data-toggle_id="feedback-form-message-' + this.modalIndex + '">' +
		   '<div class="modal-message__content js-modal-message__content"></div>' +
		'</div>' +
		'<div class="modal-body js-modal-body"><div class="modal-body__content js-modal-body__content">' + (options.text ? options.text : '')  + '</div></div>';
      modal.setAttribute('role', 'dialog');
      modal.setAttribute('data-style', '2');
      if (options.dialogId)
        modal.setAttribute('id',  options.dialogId);
      modal.style.zIndex = this.modalIndex;
      modal.setAttribute('data-modal_id',  this.modalIndex);  // unique zIndex as id
      var dialogClassList = (options.dialogClass ? options.dialogClass.split(' ') : []);  // add dialogClass string option into dialog classList
      dialogClassList.push('is-open', 'modal', 'modal-open', 'js-modal');
      options.boxClass && dialogClassList.push(options.boxClass);  // backward compatibility: boxClass
      dialogClassList.forEach(function(item) { modal.classList.add(item) });

      // display overlay
      var backdrop = document.createElement('div');
      // BOR-todo - if more modals get open simultanously, then this needs to be unique
      backdrop.setAttribute('data-id', 'backdrop'+overlayIndex);
      backdrop.classList.add('modal-backdrop', 'modal-backdrop-open', 'js-backdrop');  // TODO: modal-backdrop nelze zatim odstranit
      options.overlayClass && backdrop.classList.add(options.overlayClass);  // backward compatibility: overlayClass
      backdrop.style.zIndex = overlayIndex;
      this.backdrop = backdrop;
      document.body.appendChild(this.backdrop);

      // display modal
      this.modal = modal;
      document.body.appendChild(this.modal);
      this.activeElement = document.activeElement;
      this.modal.setAttribute('aria-hidden', 'false');
      //this.addEventListeners();
      // add event listeners
      const objModal = this; // NOTE: in any callback function 'this' is not visible so avoid using it
      // - closing modal
      this.modal.addEventForChildren('click', '.js-modal-close', function(el) {
        objModal.close();
      }, false);
      // - close on click at backdrop
      this.backdrop.addEventListener('click', function(event){
        objModal.close();
      }, false);
      // - close on click at js-modal-body which is not js-modal-body__content
      this.modal.querySelector('.js-modal-body').addEventListener('click', function(event){
        const targetElement = event.target;
        if (!targetElement.closest('.js-modal-body__content'))
          objModal.close();
      }, false);
      modals[this.modalIndex] = this;  // store modals

      // handling clearing of and adding to js-modal-message__content
      this.messageToggle = this.modal.querySelector('.js-modal-message-toggle');
      this.messageContent = this.modal.querySelector('.js-modal-message__content');

      // load dialog content - get rid of jquery - see https://blog.garstasio.com/you-dont-need-jquery/ajax/
      // NOTE: this code block is mainly replacement for calling the line with  elem.jqm(options).jqmShow()  in the original frontend/dialog : showDialog
      if (options.url)
      {
        var config = this.config;
        var modalContent = this.modal.querySelector('.js-modal-body__content');
        modalContent.evAjax2Load({
          type: (config.type?config.type:"GET"),
          url: options.url,
          success: function(responseData) {
            modalContent.innerHTML = responseData;
            //this.config.onShow(this.modal, this.activeElement)
            config.onLoad(objModal);
          },
        });
      }
      else
        this.config.onLoad(objModal);

    }

    /**
     * Close modal
     */
    close () {
      const modal = this.modal;
      const backdrop = this.backdrop;
      this.config.onClose(this);
      modal.remove();
      backdrop.remove();
    }

    /**
     * Clearing and displaying message. Using messageContent, messageToggle variables.
     */
    clearMessage () {
      this.messageContent.innerHTML = '';
      this.messageToggle.classList.add('dn');
      if (this.messageToggle.classList.contains('is-open'))
        this.messageToggle.evToggle();
    }
    addMessage (messageText, messageType = 'warning') {
      if (typeof messageText === 'undefined' || messageText === '')
        messageText = __('Unknown error occurred.')

      var message = document.createElement('div');
      message.classList.add('note');
      if (messageType)
        message.classList.add('note--'+messageType);

      const containsHTMLTags = /<\/?[a-z][\s\S]*>/i.test(messageText);
      if (!containsHTMLTags)
        messageText = '<p>'+messageText+'</p>';

      message.innerHTML = messageText;
      this.messageContent.appendChild(message);

      this.messageToggle.classList.remove('dn');
      if (!this.messageToggle.classList.contains('is-open'))
        this.messageToggle.evToggle();
    }
  }

  /**
   * evModalClass prototype ends here above.
   * Here on code is responsible for detecting and
   * auto binding event handlers on modal triggers
   */

  /**
   * showAjax - show a particular modal with ajax content returned by GET method
   * BOR-legacy: dialog shown is intended to replace evDialogAjax
   * @param  {string} title
   * @param  {string} url
   * @param  {object} config [The configuration object to pass]. Useful parameters:
   * config options for show, showAjax, error, confirm:
   *  url - ajax load dialog content url (mandatory for showAjax otherwise not used)
   *  onLoad, onClose - functions (onShow, onHide can be used too)
   *  dialogId - dialogId
   *  dialogClass - string containing class(es)
   *  overlayClass - backdrop class
   *  type  - type for ajax xhr (default GET)
   *
   * @return {object}
   */
  const showAjax = (title, url, config) => {
    // title is omitted and options are present as second argument
    // TODO: config handling left for compatibility reason
    if(!config && typeof text == 'object')
    {
      config = url;
      url = title;
      title = null;
    }
    if (!url && title)
    {
      url = title;
      title = null;
    }
    const options = config || {} ;
    options.title = title;
    options.url = url;

    // return reference to the modal
    return new evModalClass({'options':options}); // eslint-disable-line no-new
  }

  /**
   * show - show (render) particular modal with static text content
   * BOR-legacy: dialog is similar to evDialog
   * @param  {null} title
   * @param  {null} text
   * @param  {object} config [The configuration object to pass]
   * @return {object}
   */
  const show = (title, text, config) => {
    // title is omitted and options are present as second argument
    // TODO: config handling left for compatibility reason
    if(!config && typeof text == 'object')
    {
      config = text;
      text = title;
      title = null;
    }
    if (!text && title)
    {
      text = title;
      title = null;
    }
    const options = config || {} ;
    options.title = title;
    options.text = text;

    // return reference to the modal
    return new evModalClass({'options':options}) // eslint-disable-line no-new
  }

  const error = (title, text, config) => {
    var options = config || {}

    if (typeof options.dialogClass === 'undefined')
      options.dialogClass = '';

    options.dialogClass = (options.dialogClass+' modal--error maxw30rem').trim();

    if (!title) {
      title = __('An error occurred while executing the command');
    }

    // text as array of errors
    if (typeof text == 'object') {
      text = '<p>' + text.join('</p><p>') + '</p>';
    }

    // return reference to the modal
    return show(title, text, options); // eslint-disable-line no-new
  }

  /**
   * confirm - show confirm dialog
   * @param  {null} title
   * @param  {string|array} text
   * @param  {object} config [The configuration object to pass]
   * @param callback
   * @return {object}
   */
  const confirm = (title, text, config, callback) => {
    // callback, config and title are omitted (only text is sent)
    if (title && !text && !config && !callback) {
      text = title;
      title = null;
    }

    // title is empty
    if (!title) {
      title = __('Confirm action');
    }

    // config is omitted
    if (typeof config == 'function') {
      callback = config;
      config = null;
    }
    // - shifting of parameters title, text no more allowed

    if (typeof text == 'string') {
      text = [text];
    }

    var options = config || {};

    if (typeof options.dialogClass === 'undefined')
      options.dialogClass = '';


    var buttons = '';
    var buttonTextYes = options.buttonTextYes ? options.buttonTextYes : __('yes');
    var buttonTextNo = options.buttonTextNo ? options.buttonTextNo : __('no');
    var buttonActionClass = 'btn--action';
    var wrapperClass = '';

    if ($.isSet(options.type) && options.type === 'delete') {
      wrapperClass = 'wrapper-confirm-delete';
      buttonActionClass = 'btn--danger';
    }

    options.dialogClass = (options.dialogClass + ' modal--confirm maxw30rem ' + wrapperClass).trim();

    if (options.swapButtons) {
      buttons = '<button type="submit" value="'+buttonTextYes+'" class="btn ' + buttonActionClass + ' ' + confirmClass + '_yes">Yes</button><button type="submit" value="'+buttonTextNo+'" class="btn btn--secondary ' + confirmClass + '_no">No</button>';
    } else {
      buttons = '<button type="submit" value="'+buttonTextNo+'" class="btn btn--secondary ' + confirmClass + '_no">No</button><button type="submit" value="'+buttonTextYes+'" class="btn ' + buttonActionClass + ' ' + confirmClass + '_yes">Yes</button>';
    }

    var html = text[0] + '<div class="flex-gap--right mt8">' + buttons + '</div>';

    // render modal
    var modal = show(title, html, options); // eslint-disable-line no-new

    // BOR-TODO: callback after confirm buttons are clicked - this part is taken as-is using jquery so it would need refactoring to pure js
    $('.' + confirmClass + '_no', modal.modal).click(function(){
      if (options.noButton && typeof options.noButton == 'function') {
        options.noButton();
      }
      modal.close();
    });

    // show extra confirm dialog when the text parameter contains array of two texts only
    $('.' + confirmClass + '_yes', modal.modal).click(function(e){
      modal.close();
      if(text.length > 1 && options.extraConfirm){
        options.swapButtons = options.swapButtons ? ! options.swapButtons : true;
        evModal.confirm(title, text.slice(1), options, callback);
      } else if(typeof callback == 'function'){
        callback(e);
      }
    });

    return modal;
  }


  // Helping function for getting modal object
  /**
   * get evModal object by its id
   * @param  {string} modalId
   */
  const getModalById = (modalId) => {
    if (modalId && typeof modals[modalId] != 'undefined')
      return modals[modalId];
    return null;
  }

  /**
   * get evModal object by selector query, returns modal by the selector or closest
   * @param  {string} selector
   */
  const getModalByQuery = (selector) => {
    if (!selector)
      return null;
    else return getModalByElem(document.querySelector(selector));
  }

  /**
   * get evModal object by query DOM element, return modal by the node or closest
   * @param  {string} elem
   */
  const getModalByElem = (elem) => {
    if (elem)
      elem = elem.closest('.js-modal');  // get closest or current element having .js-modal class

    if (!elem || !elem.hasAttribute('data-modal_id'))
      return null;
    else
      return getModalById(elem.getAttribute('data-modal_id'));
  }

  return { show, showAjax, close, error, confirm, getModalById, getModalByQuery, getModalByElem }  // export interface functions
})();

export default evModal;
