/**
 * .js-multilevel-select handler - allows searching and selecting in simple or multilevel structure of options
 *
 * Search element
 * `<div class="js-multilevel-select"><input type="text" placeholder="Search..."></div>`
 *
 * List element:
 * `<ul class="js-multilevel-select-list">`
 *
 * List items can have explicitly set a value to be compared via attribute `data-multilevel_select_value`
 *
 * */

(function (window) {
  window.addEventListener('load', function() {

    document.body.addEventForChildren('keyup', '.js-multilevel-select', evMultilevelSelect, false);

    // evMultilevelSelect function is defined extra to distinguish between trigger by click and by javascript
    function evMultilevelSelect(searchElement) {
      if (typeof searchElement === "undefined") {
        console.log('searchElement undefined');
        return;
      }

      if (searchElement.querySelectorAll("input").length != 1) {
        console.log('searchElement does not contain a single input');
        return;
      }

      var searchVal = searchElement.querySelector("input").value.toLowerCase().trim(),
        targetId = searchElement.dataset.multilevel_select_target_id,
        classes = searchElement.dataset.classes,
        listElement = null,
        listItems = null;

      // if targetId is defined, try to find that element
      if (targetId) {
        listElement = document.querySelector("[data-multilevel_select_id='"+targetId+"']");
      }
      // if targetId is not  defined, try to find the next sibling or parent's next sibling as a default behaviour
      else {
        // first try next sibling
        var sibling = searchElement.nextElementSibling;
        // the list element must have class js-multilevel-select-list
        if (sibling && typeof sibling.classList !== 'undefined' && sibling.classList.contains('js-multilevel-select-list')) {
          listElement = sibling;
        }

        // if next sibling does not work, try parent's next sibling
        if (listElement == null) {
          var sibling = searchElement.parentElement.nextElementSibling;
          // the list element must have class js-multilevel-select-list
          if (sibling && typeof sibling.classList !== 'undefined' && sibling.classList.contains('js-multilevel-select-list')) {
            listElement = sibling;
          }
        }
      }

      // if targetElement was not found, do nothing
      if (listElement == null) {
        return;
      }

      // if all elements exist, execute the action

      // support for basic single-level list
      listItems = listElement.children;

      for (var i = 0; i < listItems.length; i++) {
        // when search field is empty, show all items
        if (!searchVal) {
          listItems[i].classList.remove("multilevel-select-item-hidden");
        }
        else {
          // by default compare textContent of an item
          var listItemValue = listItems[i].textContent;

          // it's possible to explicitly set a value to be compared via `data-multilevel_select_value` attribute
          if (listItems[i].dataset.multilevel_select_value) {
            listItemValue = listItems[i].dataset.multilevel_select_value;
          }

          if (listItemValue.toLowerCase().indexOf(searchVal) > -1) {
            listItems[i].classList.remove("multilevel-select-item-hidden");
          }
          else {
            listItems[i].classList.add("multilevel-select-item-hidden");
          }
        }
      }

      // @TODO: add support for multi-level lists search, breadcrumbs, ...
      // @TODO: add functionality for callback on click at each list item (opening sublist, adding it to list of selected)
    };

  }, false);
})
(window);
