/** 
 * .js-tabs class handler - allows to show only some part of the content upon click on related tab
 * 
 * Tabs navigation (triggers)
 * Make sure you provide following attributes:
 * * navigation wrapper with class `js-tabs` and attr `data-tab_content_container_target_id`
 * * tab elements with class `js-tab` and either attr `data-tab_content_target_id` or `data-tab_target_all="1"`
 * The default active tab should have class `active`
 * Attr `data-tab_target_all="1"` will make all tab content elems display
 * ```
 * <nav class="tabs js-tabs tabs--bordered" data-tab_content_container_target_id="foo_content_container">
 *   <div class="tab js-tab active" data-tab_target_all="1">all</div>
 *   <div class="tab js-tab" data-tab_content_target_id="foo_content1">foo content 1</div>
 *   <div class="tab js-tab" data-tab_content_target_id="foo_content2">foo content 2</div>
 * </nav>
 * ```
 * 
 * Tabs content elements and their container:
 * Make sure you provide following attributes:
 * * attr `data-tab_content_container_id` added to some element wrapping content elements for encapsulation, value should match navigation's `data-tab_content_container_target_id`
 * * tab content elements with attr `data-tab_content_id` matching navigation's `data-tab_content_target_id`
 * If some tab content elems should be hidden on page load, add clas `dn` to them
 * ```
 * <div data-tab_content_container_id="foo_content_container">
 *   <div data-tab_content_id="foo_content1">Some content 1</div>
 *   <div data-tab_content_id="foo_content2">Some content 2</div>
 * </div>
 * ```
 * 
 * */

(function (window) {

  function tabClick(activeTab) {
    if (typeof activeTab === "undefined") {
      // console.log('activeTab undefined');
      return;
    }

    let activeCls = 'active';
    let tabs = activeTab.closest('.js-tabs');
    if (!tabs) {
      // console.log('tabs click - no js-tabs element');
      return;
    }

    let tabList = tabs.querySelectorAll('.js-tab');
    let contentContainer = document.querySelector('[data-tab_content_container_id="'+tabs.dataset.tab_content_container_target_id+'"]');
    if (!tabList || !contentContainer) {
      // console.log('tabs click - no tab or contentContainer');
      return;
    }

    // showing all content when tab had data-tabs_items_all="1"
    let showAllItems = (activeTab.dataset.tab_target_all === "1");

    // loop through all items
    for (var i = 0; i < tabList.length; i++)  {

      // try to find their content element by data-tab_content_id
      var tabContent = null;
      if (tabList[i].dataset.tab_content_target_id) {
        tabContent = contentContainer.querySelector('[data-tab_content_id="'+tabList[i].dataset.tab_content_target_id+'"]');
      }

      // activate the clicked tab
      if (tabList[i] === activeTab) {
        tabList[i].classList.add(activeCls)
      } else {
        tabList[i].classList.remove(activeCls);
      }

      // display/hide content elements
      if (tabContent) {
        if (showAllItems || tabList[i] === activeTab) {
            tabContent.classList.remove('dn');
        } else {
          tabContent.classList.add('dn');
        }
      }
        
    }
  }

  // will run when all is loaded
  window.addEventListener('load', function() {
    function walkBindNodes(els) {
      if (els && typeof els.length == 'undefined')
        els = [ els ];
      for (var i = 0; i < els.length; i++)  {

        if (els[i].classList.contains('js-bound')) {
          // console.log('tab wrapper already bound');
          return;
        }

        els[i].addEventForChildren('click', '.js-tab', tabClick, false);
        els[i].classList.add('js-bound');
        els[i].querySelector('.js-tab.active').click();
      }
    }

    walkBindNodes(document.querySelectorAll('.js-tabs'));

    document.observeAddedNodes('.js-tabs', walkBindNodes);
  }, false);

  if (typeof window.evDevEnv != 'undefined' && window.evDevEnv)
    console.log('Tabs initialized');
})
(window);
