/*
 *
 * Snackbar plugin
 *
 * author: Robert Smol <robert@eventival.com>
 *
 * Usage:
 *
 *  Snackbar.info('Have fun storming the castle!');
 *  Snackbar.error('My name is Inigo Montoya. You killed my father, prepare to die!');
 *  Snackbar.success('Person Fuchs created', { duration: 5000 });
 *  Snackbar.error(
 *   'My name is Inigo Montoya. You killed my father, prepare to die! ' + counter,
 *   {
 *     duration: null,
 *     showAction: true,
 *     actionText: "fight"
 *   }
 * );
 *
 *
 * This is heavily inspired by https://github.com/CodeSeven/toastr and https://www.polonel.com/snackbar/
 *
 * What we want:
 *
 *  - specify text
 *  - specify category ( info, success, warning, error )
 *  - be able to alter defaults ( timeout, speed )
 *  - be able to show multiple snacks ( continually )
 *  - be able to wait for confirm ( dismiss action )
 *
 * How it works:
 *
 * On init it finds the provided snackbard container (create one if it does not exist).
 * When new text is added, display it, register timeout.
 * Once timeout is triggered, we remove the text and render next one.
 *
 *

 */

export default ( function( window, document, undefined ) {

  "use strict";

  var queue = [] // snacks queue
    , showing = false
    , defaultOptions = {
        duration: 3000,
        text: '',
        levelCls: '',
        showAction: false,
        actionText: 'Dismiss',
        onActionClick: function (snack) {
          snack.style.maxHeight = 0;
        }
      };

  function makeSnack() {
    if (showing || queue.length == 0 ) {
      return; // wait for next free window
    }

    showing = true;
    var options = queue.shift()
      , snack = document.createElement('div')
      , span = document.createElement('span');

    snack.className = 'snackbar-container ' + options.levelCls;
    span.innerHTML = options.text;
    snack.appendChild(span);
    snack.style.maxHeight = 0;

    // due to delay in DOM
    document.body.appendChild(snack);
    window.getComputedStyle(snack).opacity; // added
    snack.style.maxHeight = "500px";

    if ( options.duration ) {
      setTimeout(
        function () {
          this.style.maxHeight = "0px";
        }.bind(snack),
        options.duration
      );
    }

    if (options.showAction) {
      var actionButton = document.createElement('button');
      actionButton.className = 'action';
      actionButton.innerHTML = options.actionText;
      // actionButton.style.color = options.actionTextColor;
      actionButton.addEventListener('click', function () {
        options.onActionClick(snack);
      });
      snack.appendChild(actionButton);
    }

    snack.addEventListener(
      'transitionend',
      function (event, elapsed) {
        if (event.propertyName === 'max-height' && this.style.maxHeight === '0px') {
          this.parentElement.removeChild(this);
          showing = false;
          makeSnack();
        }
      }.bind(snack)
    );



    return snack
  }

  function show(options) {
    var snackOptions = Object.assign({}, defaultOptions, options);
    queue.push(snackOptions);
    makeSnack();
  }

  function info(text, options) {
    show(Object.assign({ text: text, levelCls: 'info' }, options));
  }

  function success(text, options) {
    show(Object.assign({ text: text, levelCls: 'success' }, options));
  }

  function warn(text, options) {
    show(Object.assign({ text: text, levelCls: 'warn' }, options));
  }

  function error(text, options) {
    show(Object.assign({ text: text, levelCls: 'error' }, options));
  }
  
  if (typeof window.evDevEnv != 'undefined' && window.evDevEnv)
    console.log('Snackbar initialized');

  // revealing pattern
  // https://addyosmani.com/resources/essentialjsdesignpatterns/book/#revealingmodulepatternjavascript
  var Snackbar = {
    info: info,
    success: success,
    warn: warn,
    error: error,
    show: show
  };

  return Snackbar;

} )( window, document );
